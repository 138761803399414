@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/animations/_mixins.less';
@import (reference) '../../../styles/expertAdviceShared.module.less';

.outer {
  .not-desktop({
    margin: 0 20px;

  });
  position: sticky;
  bottom: 0;
}

.container {
  width: 100%;
  .desktop({
    max-width: @maxArticleWidth;
  });
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: @color-white;
  border-top: 1px solid @color-alto;
  padding: 20px 0;
}
