.footer___2ilXp {
  bottom: 0;
  width: 100%;
  background-color: var(--background-screen, #fff);
  border-top: solid 1px #b7b7b7;
  text-align: center;
  padding: 30px;
  letter-spacing: 0.25px;
  font-size: 12px;
  color: var(--text-tertiary, #767676);
  font-weight: 400;
  z-index: 20;
}
@media (min-width: 768px) {
  .footer___2ilXp {
    height: 75px;
  }
}
.footer___2ilXp.relative___11K-Z {
  position: relative;
}
.footer___2ilXp.absolute___1GEwR {
  position: absolute;
}
.footer___2ilXp.fixed___OGq5l {
  position: fixed;
}
.links___3kkyu {
  padding-left: 4px;
}
@media (min-width: 768px) {
  .showMobile___2Xmt7 {
    display: none;
  }
}
@media (max-width: 767px) {
  .notMobile___2XmBh {
    display: none;
  }
}

.container___2OONY {
  height: 60px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .container___2OONY {
    justify-content: flex-start;
  }
}
.logo___3nZfJ {
  position: relative;
  width: 135px;
}
@media (min-width: 992px) {
  .logo___3nZfJ {
    margin-left: 40px;
  }
}

.zola-ui.v2-render-field input {
  padding: 14px 12px;
}
.zola-ui.v2-render-field input,
.zola-ui.v2-render-field.dropdown-v2__selection-box {
  border: var(--border-element, 1px solid #b7b7b7);
  border-radius: var(--border-radius-xs, 4px);
  box-sizing: border-box;
  color: var(--text-primary, #0e0e0e);
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  width: 100%;
  background-color: var(--background-light, #ffffff);
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field input,
  .zola-ui.v2-render-field.dropdown-v2__selection-box {
    height: 52px;
  }
}
.zola-ui.v2-render-field input:focus,
.zola-ui.v2-render-field.dropdown-v2__selection-box:focus {
  border: var(--border-element-selected, 1px solid #0e0e0e);
}
.zola-ui.v2-render-field input::placeholder,
.zola-ui.v2-render-field.dropdown-v2__selection-box::placeholder {
  color: var(--text-tertiary, #757575);
}
.zola-ui.v2-render-field input:disabled,
.zola-ui.v2-render-field.dropdown-v2__selection-box:disabled,
.zola-ui.v2-render-field input.is-disabled,
.zola-ui.v2-render-field.dropdown-v2__selection-box.is-disabled {
  color: var(--system-disabled, #b7b7b7);
  background-color: var(--background-container, #f3f3f3);
}
.zola-ui.v2-render-field input:disabled::placeholder,
.zola-ui.v2-render-field.dropdown-v2__selection-box:disabled::placeholder,
.zola-ui.v2-render-field input.is-disabled::placeholder,
.zola-ui.v2-render-field.dropdown-v2__selection-box.is-disabled::placeholder {
  color: var(--system-disabled, #b7b7b7);
}
.zola-ui.v2-render-field.inline {
  display: inline-block;
}
.zola-ui.v2-render-field .form-field__icon {
  margin-right: 5px;
}
.zola-ui.v2-render-field .form-field__icon.error {
  fill: var(--system-error, #c7305b);
}
.zola-ui.v2-render-field .form-field__icon.success {
  fill: var(--system-success, #15845d);
  position: relative;
  top: 1px;
  flex-shrink: 0;
}
.zola-ui.v2-render-field .text-danger {
  color: var(--system-error, #c7305b);
}
.zola-ui.v2-render-field .text-warning {
  color: var(--system-warning, #ff9a00);
}
.zola-ui.v2-render-field .text-success {
  color: var(--system-success, #15845d);
  line-height: 16px;
}
.zola-ui.v2-render-field label,
.zola-ui.v2-render-field .dropdown-v2__label {
  color: var(--text-primary, #0e0e0e);
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: 400;
}
.zola-ui.v2-render-field label .label__text,
.zola-ui.v2-render-field .dropdown-v2__label .label__text {
  color: var(--text-primary);
  margin-right: 5px;
  position: relative;
}
.zola-ui.v2-render-field label .label__tooltip,
.zola-ui.v2-render-field .dropdown-v2__label .label__tooltip {
  position: relative;
}
.zola-ui.v2-render-field label .tooltip__container,
.zola-ui.v2-render-field .dropdown-v2__label .tooltip__container {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translate(-50%, 0);
}
@media (min-width: 992px) {
  .zola-ui.v2-render-field .label__link-mobile {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .zola-ui.v2-render-field .label__link-desktop {
    display: inline-block;
  }
  .zola-ui.v2-render-field .label__link-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field .label__link-desktop {
    display: none;
  }
}
.zola-ui.v2-render-field .input-field__container {
  margin-top: 4px;
  position: relative;
}
.zola-ui.v2-render-field .input-field__container .remove-icon__container,
.zola-ui.v2-render-field .input-field__container .eyeball-icon__container {
  position: absolute;
  top: 14px;
  right: 11px;
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field .input-field__container .remove-icon__container,
  .zola-ui.v2-render-field .input-field__container .eyeball-icon__container {
    top: calc((52px - 44px) / 2 + 14px);
  }
}
.zola-ui.v2-render-field.horiz-align {
  display: flex;
}
.zola-ui.v2-render-field.horiz-align .input-field__container {
  margin-top: 0;
}
.zola-ui.v2-render-field.horiz-align label {
  margin-right: 3px;
  display: flex;
  align-items: center;
  height: 44px;
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field.horiz-align label {
    height: 52px;
  }
}
.zola-ui.v2-render-field .danger-asterisk {
  position: static;
  left: auto;
  line-height: normal;
  font-size: 16px;
  margin-left: -2px;
}
.zola-ui.v2-render-field.has-add-on .input-addon {
  position: absolute;
  top: calc(44px / 2 - 16px / 2);
  left: 13px;
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field.has-add-on .input-addon {
    top: calc(52px / 2 - 16px / 2);
  }
}
.zola-ui.v2-render-field.has-add-on .input-addon-calendar {
  top: calc(44px / 2 - 18px / 2);
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field.has-add-on .input-addon-calendar {
    top: calc(52px / 2 - 18px / 2);
  }
}
.zola-ui.v2-render-field.has-add-on .input-addon-geopin {
  top: calc(44px / 2 - 14 / 2);
}
@media (max-width: 767px) {
  .zola-ui.v2-render-field.has-add-on .input-addon-geopin {
    top: calc(52px / 2 - 14 / 2);
  }
}
.zola-ui.v2-render-field.has-add-on input {
  padding-left: calc(16px + 21px);
}
.zola-ui.v2-render-field.has-add-on .addon.calendar {
  height: 18px;
  width: 18px;
}
.zola-ui.v2-render-field.has-add-on .addon.cash {
  height: 16px;
  width: 16px;
}
.zola-ui.v2-render-field.has-add-on .addon.hashtag {
  height: 16px;
  width: 16px;
}
.zola-ui.v2-render-field .copy-link-cta {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.zola-ui.v2-render-field .copy-link-cta__mobile {
  display: none;
}
.zola-ui.v2-render-field.has-error input,
.zola-ui.v2-render-field.has-error.dropdown-v2__selection-box {
  border: 1px solid var(--system-error, #c7305b);
}
.zola-ui.v2-render-field.has-warning input {
  border: 1px solid var(--system-warning, #ff9a00);
}
.zola-ui.v2-render-field.has-success input {
  border: 1px solid var(--system-success, #15845d);
}
.zola-ui.v2-render-field .field-message-container {
  margin-top: 5px;
}
.zola-ui.v2-render-field .field-message {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-weight: 400;
}
.zola-ui.v2-render-field .field-message .max-characters {
  margin-left: auto;
  font-weight: 400;
}
.zola-ui.v2-render-field .hidden {
  display: none;
}
@media (max-width: 768px) {
  .zola-ui.v2-render-field .copy-link-cta__desktop {
    display: none;
  }
  .zola-ui.v2-render-field .copy-link-cta__mobile {
    display: inline-block;
    font-size: 18px;
  }
  .zola-ui.v2-render-field .copy-link-cta__mobile:hover {
    border-bottom: none;
  }
}
.zola-ui.v2-render-selector-field input[type='checkbox'],
.zola-ui.v2-render-selector-field input[type='radio'] {
  visibility: hidden;
  position: absolute;
}
.zola-ui.v2-render-selector-field .field-wrapper {
  display: block;
  color: var(--text-primary, #0e0e0e);
  cursor: pointer;
  font-weight: 400;
}
.zola-ui.v2-render-selector-field .field-wrapper .form-field__icon.info {
  color: var(--text-invert, #ffffff);
  position: relative;
  top: 1px;
  left: 1px;
}
.zola-ui.v2-render-selector-field .field-wrapper .label__tooltip {
  position: relative;
  vertical-align: middle;
  margin-left: 5px;
}
.zola-ui.v2-render-selector-field .field-wrapper .tooltip__container {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translate(-50%, 0);
}
.zola-ui.v2-render-selector-field .field-wrapper .icon-wrapper {
  position: relative;
  top: -8px;
}
.zola-ui.v2-render-selector-field .field-wrapper .field-wrapper-inner {
  display: flex;
}
.zola-ui.v2-render-selector-field .field-wrapper .field-wrapper-checkbox {
  align-items: flex-start;
  font-size: 16px;
  text-transform: none;
  line-height: 1.4286;
  letter-spacing: 0;
}
.zola-ui.v2-render-selector-field .field-wrapper .field-body {
  width: 100%;
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper {
  position: relative;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 4px;
  vertical-align: middle;
  border: var(--border-element, 1px solid #b7b7b7);
  background-color: var(--background-light, #ffffff);
  transition: 0.3s;
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper--checked {
  background-color: var(--interactive-primary, #0e0e0e);
  border-color: var(--interactive-primary, #0e0e0e);
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper--disabled {
  border-color: var(--system-disabled, #b7b7b7);
  background-color: var(--background-container, #f3f3f3);
  box-shadow: none;
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper:not(.zola-ui.v2-render-selector-field .selector-icon-wrapper--checked):not(.zola-ui.v2-render-selector-field .selector-icon-wrapper--disabled):hover {
  border: var(--border-element-hover, 1px solid #0e0e0e);
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper.checkbox-icon-wrapper {
  top: initial;
}
.zola-ui.v2-render-selector-field .selector-dek {
  color: var(--text-tertiary, #757575);
  font-size: 14px;
  margin: 0;
}
.zola-ui.v2-render-selector-field .selector-label {
  color: var(--text-primary, #0e0e0e);
}
.zola-ui.v2-render-selector-field .selector-label--checked {
  color: var(--text-primary, #0e0e0e);
}
.zola-ui.v2-render-selector-field .selector-label--disabled {
  color: var(--system-disabled, #b7b7b7);
}
.zola-ui.v2-render-selector-field.uses-selector-style {
  background: var(--background-light, #ffffff);
  border: var(--border-element, 1px solid #dbdbdb);
  border-radius: 4px;
}
.zola-ui.v2-render-selector-field.uses-selector-style.is-checked {
  border-color: var(--interactive-primary, #0e0e0e);
}
.zola-ui.v2-render-selector-field.uses-selector-style > .field-wrapper {
  padding: 20px;
}
.zola-ui.v2-render-selector-field.uses-selector-style > .field-wrapper:hover {
  background: var(--background-light, #ffffff);
  border-radius: 4px;
}
.zola-ui.v2-render-selector-field.uses-selector-style .selector-label {
  color: var(--text-primary, #0e0e0e);
}
.zola-ui.v2-render-selector-field.uses-selector-style .selector-label-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}
.zola-ui.v2-render-selector-field.uses-selector-style .selector-label-wrapper.has-icon {
  margin-bottom: 4px;
}
.zola-ui.v2-render-selector-field.uses-selector-style .selector-label-right {
  margin-left: 12px;
  font-weight: 600;
  min-width: 36px;
  height: 28px;
  text-align: right;
}
@media (max-width: 767px) {
  .zola-ui.v2-render-selector-field.uses-selector-style .selector-label-right {
    min-width: 52px;
  }
}
.zola-ui.v2-render-selector-field.uses-selector-style:hover label.selector-icon-wrapper {
  border-color: var(--text-tertiary, #757575);
}
.zola-ui.v2-render-selector-field.uses-selector-style .expanded-area {
  background: var(--background-light, #ffffff);
  border-radius: 0 0 4px 4px;
  padding: 20px;
  border-top: var(--border-element, 1px solid #b7b7b7);
}
.zola-ui.v2-render-selector-field.uses-selector-style.v2-render-radio-field.is-disabled .field-wrapper {
  border-color: var(--system-disabled, #b7b7b7);
  color: var(--text-primary, #0e0e0e);
  background-color: var(--background-container, #f3f3f3);
  cursor: default;
}
.zola-ui.v2-render-selector-field.uses-selector-style.v2-render-radio-field.is-disabled .selector-icon-wrapper--disabled {
  background-color: var(--background-light, #ffffff);
}
.zola-ui.v2-render-selector-field.uses-selector-style.v2-render-radio-field.is-disabled .selector-dek,
.zola-ui.v2-render-selector-field.uses-selector-style.v2-render-radio-field.is-disabled .selector-label--disabled {
  color: var(--system-disabled, #b7b7b7);
}
.zola-ui.v2-render-selector-field .radio-icon-wrapper {
  flex: 0 0 auto;
  border-radius: 50%;
  box-shadow: inset 0 0 0 5px var(--background-light, #ffffff);
}
@media (max-width: 767px) {
  .zola-ui.v2-render-selector-field .radio-icon-wrapper {
    width: 28px;
    height: 28px;
  }
}
.zola-ui.v2-render-selector-field .zola-ui-icon-checkmark {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-invert, #ffffff);
}
.zola-ui.v2-render-selector-field .zola-ui-icon-checkmark::before {
  margin: 0;
}
.zola-ui.v2-render-selector-field .checkmark-svg {
  color: var(--text-invert, #ffffff);
  display: none;
  position: relative;
  top: -1px;
}
.zola-ui.v2-render-selector-field.inline {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 0;
}
.zola-ui.v2-render-selector-field .selector-icon--checked {
  display: flex;
  justify-content: center;
  align-items: center;
}
.zola-ui.v2-render-selector-field.has-error {
  border-color: var(--system-error, #c7305b);
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper--errored {
  border-color: var(--system-error, #c7305b);
  background-color: var(--background-light, #ffffff);
}
.zola-ui.v2-render-selector-field .selector-icon-wrapper--checked.selector-icon-wrapper--errored {
  background-color: var(--system-error, #c7305b);
  border-color: var(--system-error, #c7305b);
}

.hero___1gv3u {
  display: flex;
}
@media (max-width: 991px) {
  .hero___1gv3u {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .borderBottom___3MzYK {
    border-bottom: solid 1px #d9d9d9;
  }
}
@media (min-width: 992px) {
  .heroCopyContainer___1xA7N {
    width: 50%;
  }
}
.heroCopyContainer___1xA7N p {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .title___2Rsyo {
    font-size: 40px;
    line-height: 52px;
  }
}
.pageExcerpt___Q6W0f {
  margin-top: 16px;
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .pageExcerpt___Q6W0f {
    margin-top: 20px;
    margin-bottom: 24px;
  }
}
.author___57fHm {
  margin-bottom: 4px;
}
.updatedAt___-WO2n {
  margin-bottom: 16px !important;
  font-size: 14px !important;
}
.updatedAt___-WO2n {
  margin-bottom: 12px !important;
  font-size: 14px !important;
}
.heroImageContainer____2fRG {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
@media (min-width: 992px) {
  .heroImageContainer____2fRG {
    width: 50%;
    margin-left: 30px;
  }
}
.heroImageContainer____2fRG img {
  width: 100%;
}
.heroCredit___3DmZA {
  font-weight: 600;
  font-size: 14px;
}

.contentContainer___1DmYV {
  padding: 0 40px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .contentContainer___1DmYV {
    padding: 16px 24px;
  }
}

.zola-ui.circle-icon {
  border-radius: 50%;
}
.zola-ui.circle-icon.cream {
  background-color: #f6f5f0;
}
.zola-ui.circle-icon.white {
  background-color: #ffffff;
}
.zola-ui.circle-icon.transparent {
  background-color: transparent;
}

.dropdown-v2__container {
  position: relative;
  min-width: 150px;
  color: #0e0e0e;
  font-size: 16px;
}
.dropdown-v2__container.inline {
  display: inline-block;
}
.dropdown-v2__container.inline-label {
  display: flex;
  align-items: center;
}
.dropdown-v2__container.inline-label.inline {
  display: inline-flex;
}
.dropdown-v2__container.inline-label .dropdown-v2__label {
  margin-right: 8px;
  margin-bottom: 0;
}
.dropdown-v2__container .dropdown-v2__options-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-v2__container .dropdown-v2__options {
  cursor: pointer;
  margin-top: 4px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  position: absolute;
  background: #ffffff;
  z-index: 20;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
@media (max-width: 480px) {
  .dropdown-v2__container .dropdown-v2__options {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    border: none;
    height: 100vh;
    max-height: none;
  }
}
.dropdown-v2__container .dropdown-v2__options-mobile-header {
  display: none;
  border-bottom: 1px solid #b7b7b7;
  text-align: center;
  padding: 20px 0;
  font-size: 24px;
}
@media (max-width: 480px) {
  .dropdown-v2__container .dropdown-v2__options-mobile-header {
    display: block;
  }
}
.dropdown-v2__container .dropdown-v2__options-mobile-header .options-header-icon {
  position: absolute;
  right: 15px;
  top: 21px;
  font-size: 24px;
}
.dropdown-v2__container .dropdown-v2__options .dropdown-v2__option {
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
}
.dropdown-v2__container .dropdown-v2__options .dropdown-v2__option:first-of-type {
  padding-top: 12px;
}
.dropdown-v2__container .dropdown-v2__options .dropdown-v2__option:last-of-type {
  padding-bottom: 12px;
}
.dropdown-v2__container .dropdown-v2__options .dropdown-v2__option:hover {
  background-color: #f3f3f3;
  color: #0e0e0e;
}
.dropdown-v2__container .dropdown-v2__options .dropdown-v2__option.dropdown-v2__option-selected {
  font-weight: 600;
}
.dropdown-v2__container .dropdown-v2__selection-box-icon {
  font-size: 14px;
  color: #0e0e0e;
  margin: 2px 4px 0 8px;
  transform: rotate(90deg);
  transition: all 0.2 ease;
}
.dropdown-v2__container .dropdown-v2__selection-box {
  cursor: pointer;
  padding: 9px 4px 9px 12px;
  display: flex;
  justify-content: space-between;
}
.dropdown-v2__container .dropdown-v2__selection-box.is-disabled .dropdown-v2__selection-box-icon.zolaicon {
  color: #b7b7b7;
}
.dropdown-v2__container .dropdown-v2__selection-box:focus {
  outline: none;
}
.dropdown-v2__container .dropdown-v2__selection-box.expanded {
  border: 1px solid #0e0e0e;
}
.dropdown-v2__container .dropdown-v2__selection-box.expanded .dropdown-v2__selection-box-icon.zolaicon {
  transform: rotate(270deg);
  margin-right: 1px;
  margin-left: 11px;
  margin-top: 0;
  transition: all 0.2 ease;
  color: #0e0e0e;
}
.dropdown-v2__container .dropdown-v2__selection-box.dropdown-v2__selection-box--large {
  padding: 13px 4px 13px 12px;
}
@media (max-width: 767px) {
  .dropdown-v2__container .dropdown-v2__selection-box {
    padding: 13px 4px 13px 12px;
  }
}
.dropdown-v2__container .dropdown-v2__selection-box-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-v2__selection-box.no-option-selected:not(.expanded) {
  color: #b7b7b7;
}

.nav___2DM8x {
  position: relative;
  display: none;
  z-index: unset !important;
  min-width: unset !important;
}
@media (max-width: 991px) {
  .nav___2DM8x {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .nav___2DM8x img {
    height: 16px;
  }
}
.logoRebrand___2P266 {
  width: 151px;
  height: 20px;
  margin: 0 auto 0 24px;
}

.container___7NcSD {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sponsorName____m1P5 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.button___32Q_V {
  margin-top: 32px;
}

.markdown___3vGiu h2 {
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .markdown___3vGiu h2 {
    margin-top: 40px;
  }
}
.markdown___3vGiu h3 {
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 4px;
}
.markdown___3vGiu h4 {
  font-size: 20px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 32px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.markdown___3vGiu ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 32px;
}
.markdown___3vGiu ul p {
  font-size: 20px;
}
.markdown___3vGiu ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  font-size: 20px;
  line-height: 32px;
}
.markdown___3vGiu ol p {
  font-size: 20px;
}
.markdown___3vGiu blockquote {
  margin-bottom: 40px;
  margin-top: 20px;
}
.markdown___3vGiu > :first-child {
  margin-top: 0;
}
.markdown___3vGiu > :last-child {
  margin-bottom: 0;
}
.markdown___3vGiu > p > a > img {
  max-width: 100%;
}
.markdown___3vGiu > p {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 32px;
}
.markdown___3vGiu a {
  font-weight: 600;
}
.markdown___3vGiu hr {
  margin: 20px 0;
  border-top: 1px solid #f7f7f7;
}
.markdown___3vGiu :target::before {
  content: '';
  display: block;
  visibility: hidden;
  height: calc(136px + 56px);
  margin-top: calc(-1 * calc(136px + 56px));
}
@media (max-width: 991px) {
  .markdown___3vGiu :target::before {
    height: calc(calc(60px + 56px));
    margin-top: calc(-1 * calc(60px + 56px));
  }
}
.photoCredit___1KTUp {
  font-size: 12px;
  line-height: 20px;
  text-align: right;
}
.photoCredit___1KTUp em {
  font-style: normal;
}
.pStyling___2dumS {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
.image___2kWXW img {
  width: 100%;
  border-radius: 16px;
}
.topSpacing___18P1w {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .topSpacing___18P1w {
    margin-top: 40px;
  }
}
.bottomSpacing___29x2n {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .bottomSpacing___29x2n {
    margin-bottom: 40px;
  }
}
.negativeBottomSpacing___1s1xk {
  margin-top: calc(-1 * 40px);
}
@media (min-width: 992px) {
  .negativeBottomSpacing___1s1xk {
    margin-top: calc(-1 * 60px);
  }
}
.videoContainer___2WFjR {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.videoContainer___2WFjR iframe,
.videoContainer___2WFjR object,
.videoContainer___2WFjR embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container___pOSeX {
  padding-top: 40px;
}
.sponsoredContainer___2g646 {
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}
.sponsoredBodyContainer___21hMF {
  margin-top: 24px;
  background-color: #f7f7f7;
  padding-bottom: 60px;
  margin-bottom: 60px;
}
.sponsoredBodyMarkdown___2VcA2 * {
  font-weight: 600;
}
.sponsoredAboutContainer___14ojo {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 60px 40px;
  margin-right: -20px;
  margin-left: -20px;
}

.articlePageBody___3CqNm .articlePageBodyInner___3qIGr {
  max-width: 680px;
  margin: 40px auto 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .articlePageBody___3CqNm .articlePageBodyInner___3qIGr {
    margin: 0 auto 40px;
  }
}
@media (max-width: 767px) {
  .articlePageBody___3CqNm .articlePageBodyInner___3qIGr {
    margin: 8px 24px 40px;
  }
}
.articlePageBody___3CqNm .title___2YGJz {
  font-weight: 500;
}
@media (max-width: 991px) {
  .articlePageBody___3CqNm .title___2YGJz {
    font-size: 32px;
    line-height: 40px;
  }
}
.articlePageBody___3CqNm .excerpt___4cfzh {
  margin-top: 16px;
}
.articlePageBody___3CqNm .author___oAyCk {
  font-weight: 600;
  margin-top: 16px;
}
.articlePageBody___3CqNm .updatedAt___3qtXF {
  margin-top: 8px;
}
.articlePageBody___3CqNm .heroImageContainer___IsAfx {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.articlePageBody___3CqNm .heroImageContainer___IsAfx img {
  width: 100%;
  border-radius: 16px;
}
.articlePageBody___3CqNm .heroCredit___3DD2a {
  font-size: 12px;
  text-align: right;
}
.articlePageBody___3CqNm .tldr___2svTJ {
  margin-top: 40px;
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 16px;
  font-size: 16px;
}
@media (min-width: 992px) {
  .articlePageBody___3CqNm .tldr___2svTJ {
    padding: 40px;
  }
}
.articlePageBody___3CqNm .tldr___2svTJ h2 {
  font-size: 20px;
}
.articlePageBody___3CqNm .tldr___2svTJ a,
.articlePageBody___3CqNm .tldr___2svTJ li {
  font-size: 16px;
}
.featuredArticles___3Sv2J {
  border-top: 1px solid #b7b7b7;
}
.seoRelatedLinks___3iCYH {
  border-bottom: 1px solid #b7b7b7;
}
.seoRelatedLinks___3iCYH > div {
  border-top: none;
  margin: 0;
}

@media (min-width: 768px) {
  .hideOnTabletDesktop___1pPej {
    display: none;
  }
}
@media (max-width: 767px) {
  .hideOnMobile___306MF {
    display: none;
  }
}
.totalItemPagination___CUcS3 {
  display: flex;
}
@media (max-width: 767px) {
  .totalItemPagination___CUcS3 {
    display: block;
  }
}
.totalItemPaginationInner___2GtjF {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.totalItemPaginationInner___2GtjF .arrowPageButton___30RlT {
  margin: 0 28px;
}
@media (max-width: 767px) {
  .totalItemPaginationInner___2GtjF .arrowPageButton___30RlT {
    margin: 0 14px;
  }
}
.totalItemPaginationInner___2GtjF .arrowPageButton___30RlT .zui-svg-icon {
  position: relative;
  bottom: -3px;
}
.totalItemPaginationInner___2GtjF .arrowPageButton___30RlT .disabled___1FpD6 {
  color: var(--system-disabled, #b7b7b7);
}
.totalItemPaginationInner___2GtjF .arrowPageButton___30RlT .disabled___1FpD6:hover {
  text-decoration: none;
  cursor: default;
  color: var(--system-disabled, #b7b7b7);
}
.totalItemPaginationInner___2GtjF .numberPageButton___2RUxb.currentPage___1kY2Y {
  background-color: #4a4a4a;
  color: #ffffff;
}
.totalItemPaginationInner___2GtjF .numberPageButton___2RUxb.currentPage___1kY2Y:hover {
  background-color: #4a4a4a;
}
.totalItemPaginationInner___2GtjF .numberPageButton___2RUxb {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 8px;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
}
.totalItemPaginationInner___2GtjF .numberPageButton___2RUxb:hover {
  background-color: #e6e6e6;
}

.videoContainer___3HxoI {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.videoContainer___3HxoI iframe,
.videoContainer___3HxoI object,
.videoContainer___3HxoI embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title___1Aqjj {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  color: #003b58;
  margin: 0;
  font-size: 32px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .title___1Aqjj {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .title___1Aqjj {
    font-size: 56px;
  }
}

.zola-ui.pre-auth-porthole-image .porthole-img__container {
  display: inline-block;
  margin-top: 8px;
  width: 115px;
  height: 115px;
}
@media (max-width: 992px) {
  .zola-ui.pre-auth-porthole-image .porthole-img__container {
    width: 80px;
    height: 80px;
  }
}
.zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
  border-radius: 50%;
  height: auto;
  width: 100%;
  overflow: hidden;
}
.zola-ui.pre-auth-porthole-image.info-tile__image.info-tile__image--v2 .porthole-img__container {
  width: 68px;
  height: 68px;
  display: inline-block;
}
@media (min-width: 768px) {
  .zola-ui.pre-auth-porthole-image.info-tile__image.info-tile__image--v2 .porthole-img__container {
    width: 140px;
    height: 140px;
  }
}

.info-tile--pointer {
  cursor: pointer;
}
.zola-ui.info-tile {
  display: flex;
  align-items: flex-start;
  /* -- Different layout variant -- */
  /* -- Different layout variant -- */
}
@media (min-width: 768px) {
  .zola-ui.info-tile {
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
    max-width: 313px;
  }
}
.zola-ui.info-tile .info-tile__content {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .zola-ui.info-tile .info-tile__content {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .zola-ui.info-tile .info-tile__image {
    padding-right: 15px;
  }
}
.zola-ui.info-tile .info-tile__heading {
  color: #003b58;
  font-weight: 600;
  letter-spacing: normal;
  font-size: 20px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .zola-ui.info-tile .info-tile__heading {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}
@media (min-width: 768px) {
  .zola-ui.info-tile .info-tile__heading:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .zola-ui.info-tile .info-tile__cta {
    display: none;
  }
}
.zola-ui.info-tile .info-tile__cta .cta-v2 {
  text-align: left;
}
.zola-ui.info-tile .info-tile__cta--link {
  color: #0075ae;
  font-weight: 600;
  font-size: 16px;
}
@media (min-width: 768px) {
  .zola-ui.info-tile .info-tile__cta--link {
    display: none;
  }
}
.zola-ui.info-tile .info-tile__cta--link:hover {
  color: #0090d6;
}
.zola-ui.info-tile .info-tile__dek {
  line-height: 28px;
  font-weight: 400;
}
.zola-ui.info-tile.info-tile--column {
  flex-direction: column;
  align-items: center;
}
.zola-ui.info-tile.info-tile--column .info-tile__image {
  padding-right: 0;
}
.zola-ui.info-tile.info-tile--column .info-tile__heading {
  text-align: center;
  padding: 0 10px;
  line-height: 1.6;
}
@media (max-width: 767px) {
  .zola-ui.info-tile.info-tile--column .info-tile__heading {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .zola-ui.info-tile.info-tile--moreSpace {
    margin-top: 25px;
    align-items: flex-start;
  }
  .zola-ui.info-tile.info-tile--moreSpace .info-tile__image {
    padding-right: 25px;
  }
  .zola-ui.info-tile.info-tile--moreSpace .info-tile__dek {
    margin-top: 10px;
  }
}

.cta-v2 {
  font-size: 16px;
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}
.cta-v2--bold {
  font-weight: 700;
}
.cta-v2--larger {
  font-size: 20px;
}
.cta-v2--with-arrow {
  white-space: nowrap;
}
.cta-v2--with-arrow::after {
  content: '';
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDkgOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNjAuMSAoODgxMzMpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPkZpbGwgMTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iTGVhcm5Nb3JlTGluayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkwLjAwMDAwMCwgLTguMDAwMDAwKSIgZmlsbD0iIzAwNzVBRSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05OC42NTMsMTIuNDgzIEM5OC44MDgsMTIuMzI3IDk4LjkwNSwxMi4xMTIgOTguOTA1LDExLjg3NCBDOTguOTA1LDExLjYzMiA5OC44MDQsMTEuNDE1IDk4LjY0NCwxMS4yNTcgTDk1LjY1Niw4LjI2OSBDOTUuNDk4LDguMTA1IDk1LjI3OSw4IDk1LjAzMyw4IEM5NC41NTUsOCA5NC4xNjgsOC4zODggOTQuMTY4LDguODY2IEM5NC4xNjgsOS4wOTkgOTQuMjYyLDkuMzEgOTQuNDEzLDkuNDY1IEw5NC40MTEsOS40NjggTDk1Ljk1NSwxMS4wMTIgTDkwLjg4MSwxMS4wMTIgQzkwLjg3NiwxMS4wMTIgOTAuODcxLDExLjAwOSA5MC44NjUsMTEuMDA5IEM5MC4zODcsMTEuMDA5IDkwLDExLjM5NiA5MCwxMS44NzQgQzkwLDEyLjM1IDkwLjM4NCwxMi43MzQgOTAuODU5LDEyLjczOCBMOTAuODU5LDEyLjc0IEw5NS45NTgsMTIuNzQgTDk0LjQxNiwxNC4yODEgTDk0LjQxOSwxNC4yODQgQzk0LjI2LDE0LjQ0MSA5NC4xNjIsMTQuNjU4IDk0LjE2MiwxNC44OTggQzk0LjE2MiwxNS4zNzYgOTQuNTQ5LDE1Ljc2MyA5NS4wMjgsMTUuNzYzIEM5NS4yNzksMTUuNzYzIDk1LjUsMTUuNjUzIDk1LjY1OCwxNS40ODMgTDk4LjY1NiwxMi40ODUgTDk4LjY1MywxMi40ODMgWiIgaWQ9IkZpbGwtMSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 4px;
  vertical-align: middle;
}
button.cta-v2 {
  background: none;
  border: none;
  padding: 0;
}
@media (max-width: 767px) {
  a.cta-v2 {
    display: block;
  }
}
.cta-v2--blue {
  color: #0075ae;
}
.cta-v2--white {
  color: #ffffff;
}

.carousel___2hwdz {
  position: relative;
}
.arrow___1Yl1m {
  position: absolute;
  top: calc(50% - (44px / 2));
}
.rightArrow___3rhuv {
  right: 0;
}
.leftArrow___35f9V {
  left: 0;
}
.infoTile___35gog .porthole-img__container {
  background-color: #ffffff;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .infoTile___35gog .porthole-img__container {
    margin-bottom: 10px;
  }
}
.infoTile___35gog .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
  width: 75%;
  border-radius: 0 !important;
}
.infoTileColumn___35nMI {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.infoTileColumn___35nMI > :first-child {
  margin-bottom: 32px;
}

.secondaryImage___1Cuzx {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 992px) {
  .secondaryImage___1Cuzx {
    position: absolute;
    left: -20%;
    top: -150px;
    height: 675px;
    width: 135%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondaryImage___1Cuzx {
    height: 360px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .secondaryImage___1Cuzx {
    max-height: 300px;
    width: calc(100vw - 40px);
  }
}
.imgContainer___qfIrI {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.secondaryContentsOverride___2BThQ {
  margin-top: 20px;
}
@media (max-width: 991px) {
  .secondaryContentsOverride___2BThQ {
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
}
.subtitle___1Izc3 {
  margin-top: 82px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .subtitle___1Izc3 {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .subtitle___1Izc3 {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .subtitle___1Izc3 {
    text-align: center;
    max-width: 450px;
  }
}
.title___2xWbH {
  max-width: 450px;
  margin: auto;
}
@media (min-width: 992px) {
  .title___2xWbH {
    margin-top: 45px;
  }
}
@media (min-width: 992px) {
  .btnSpacing___3USSC {
    margin-top: 32px;
  }
}
@media (max-width: 767px) {
  .btnSpacing___3USSC a {
    width: 100%;
  }
}
.shadow___1N8ND {
  background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/oval-a119f7b26b5d296db55b53afbcbc0b28.svg);
  background-position: bottom;
  background-size: cover;
  width: 100%;
  position: absolute;
  z-index: 0;
  height: calc(100vw + ((100vw - 40px) * 364 / 662));
  top: calc(-100vw + 409px);
}
@media (min-width: 992px) {
  .shadow___1N8ND {
    display: none;
  }
}
@media (min-width: 400px) {
  .shadow___1N8ND {
    height: calc(400px + ((360px - 40px) * 364 / 662));
    top: calc(-400px + 317px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shadow___1N8ND {
    height: calc(768px + ((768px - 100px) * 768 / 930));
    top: calc(-768px + 86px);
  }
}
.infoTilesContainer___3xMhx {
  width: 100%;
  max-width: 1280px;
  padding: 0px 20px 52px 20px;
  margin-top: -30px;
}
@media (min-width: 992px) {
  .infoTilesContainer___3xMhx {
    padding: 0px 30px 60px 30px;
  }
}
@media (max-width: 991px) {
  .infoTilesContainer___3xMhx {
    margin-top: -52px;
  }
}
.outerColor___2qJOw {
  background-color: #f7f7f7;
}

.secondary-image___Pl2MU {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-image___Pl2MU {
    height: 360px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .secondary-image___Pl2MU {
    max-height: 240px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  }
}

.secondary-image___VR5Ex {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-image___VR5Ex {
    height: 360px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .secondary-image___VR5Ex {
    max-height: 240px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  }
}

.mobileAppBadge___2HXKu + .mobileAppBadge___2HXKu {
  margin-left: 10px;
}
.mobileAppBadgeImg___qbr5D {
  height: 48px;
  width: auto;
}

.secondary-image___3r81B {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-image___3r81B {
    height: 360px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .secondary-image___3r81B {
    max-height: 240px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  }
}

.secondary-image___2FXiU {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-image___2FXiU {
    height: 360px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .secondary-image___2FXiU {
    max-height: 240px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  }
}

.lightGrayBg___3Pkj5 {
  background-color: #f7f7f7;
}
.paleYellow___m438b {
  background-color: #ffebb1;
}
.cardsContainer___3nGZa {
  display: flex;
}
@media (max-width: 991px) {
  .cardsContainer___3nGZa {
    flex-direction: column;
  }
}

.container___1raDz {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 272px;
  padding: 0 10px;
}
@media (max-width: 767px) {
  .container___1raDz {
    height: 265px;
  }
}
.title___3X9bi {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  color: #003b58;
  margin-top: 0;
}
@media (max-width: 767px) {
  .title___3X9bi {
    margin-bottom: 13px;
  }
}
@media (min-width: 768px) {
  .title___3X9bi {
    margin-bottom: 11px;
  }
}
@media (max-width: 767px) {
  .title___3X9bi {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 768px) {
  .title___3X9bi {
    font-size: 24px;
    line-height: 32px;
  }
}
.dek___2wlu7 {
  font-family: 'circular', helvetica, sans-serif;
  color: #505050;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .dek___2wlu7 {
    max-width: calc(100% - 52.5px * 2);
  }
}
@media (min-width: 768px) {
  .dek___2wlu7 {
    max-width: 459px;
  }
}
.footer___Bk1eB {
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .footer___Bk1eB {
    margin-top: 13px;
  }
}
@media (min-width: 768px) {
  .footer___Bk1eB {
    margin-top: 11px;
  }
}

.lowestCashFundContainer___1td6F {
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/lowest_cash_fund_desktop-203d2c2ce861a62c1cb23b500db08c98.svg);
}
@media (min-width: 768px) and (max-width: 991px) {
  .lowestCashFundContainer___1td6F {
    background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/lowest_cash_fund_tablet-95437ec2d98cee448cd2a44dd8258eb6.svg);
  }
}
@media (max-width: 767px) {
  .lowestCashFundContainer___1td6F {
    background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/lowest_cash_fund_mobile-b8087de32669cb909733a52b82094b0c.svg);
  }
}
.postWeddingCardContainer___uLheG {
  background-color: #fee6e8;
  background-size: cover;
  background-position: center;
  background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/post-wedding-discount-desktop-bd3072e03a45998bdfaedf3d8a07e264.svg);
}
@media (min-width: 768px) and (max-width: 991px) {
  .postWeddingCardContainer___uLheG {
    background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/post-wedding-discount-tablet-64b20849b4eb5f889aea97d59ba47f7e.svg);
  }
}
@media (max-width: 767px) {
  .postWeddingCardContainer___uLheG {
    background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/post-wedding-discount-mobile-7e570fbefcb5a2581a4f8c86f6e6eaf0.svg);
  }
}
.plumText___P5fJP {
  color: #733658;
}

.registryAdvisorContainer___3wOuo {
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/registry_advisor-0e434f1993d8af328ec1746a2642ad5b.svg);
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .registryAdvisorContainer___3wOuo {
    background-size: 150px;
    background-position: bottom -25px left -50px;
  }
}
@media (min-width: 992px) {
  .registryAdvisorContainer___3wOuo {
    background-size: 150px;
    background-position: bottom -25px left -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registryAdvisorContainer___3wOuo {
    background-size: 225px;
    background-position: bottom -40px left -75px;
  }
}
.reviewContainer___1E9Y3 {
  background-color: #733658;
}
.reviewText___3hRO5 {
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 500;
}
.whiteText___3SWRy {
  color: #ffffff;
}
.thinText___3DUBw {
  font-weight: 400;
}

.info-tiles-section {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .info-tiles-section {
    margin-bottom: 32px;
  }
}
.info-tiles-section--rose {
  background-color: #ffe2dd;
}
.info-tiles-section--secondary-background-gray {
  background-color: #f7f7f7;
}
.info-tiles-section--light-periwinkle {
  background-color: #e5eef7;
}
.info-tiles-section--highlight-blue {
  background-color: #e8f4f6;
}
.info-tiles-section__title,
.info-tiles-section__title.zui__h2 {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 32px;
  color: #003b58;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .info-tiles-section__title,
  .info-tiles-section__title.zui__h2 {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .info-tiles-section__title,
  .info-tiles-section__title.zui__h2 {
    font-size: 40px;
    margin-bottom: 40px;
  }
}
.info-tiles-section__dek {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 481px) {
  .info-tiles-section__dek {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .info-tiles-section__tiles {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
}
.info-tiles-section__tile {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .info-tiles-section__tile {
    display: flex;
    flex-direction: column;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 32px;
    align-items: center;
  }
}
.info-tiles-section__tile .zola-ui.info-tile {
  width: 100%;
}
.info-tiles-section.info-tiles-section--twoColumn .info-tiles-section__tiles {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .info-tiles-section.info-tiles-section--twoColumn .info-tiles-section__tile {
    flex: 0 0 50%;
    margin-top: 0;
    margin-bottom: 24px;
  }
}
@media (min-width: 992px) {
  .info-tiles-section.info-tiles-section--twoColumn .info-tiles-section__tile {
    flex: 0 0 25%;
  }
}
.info-tiles-section.info-tiles-section--twoColumn .info-tile__heading.pre-auth__h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #003b58;
}

.lightGrayBg___3Hph8 {
  background-color: #f7f7f7;
}
.paleYellow___1Xu9P {
  background-color: #ffebb1;
}
@media (min-width: 992px) {
  .infoTilesSection___ki3wI .info-tiles-section__tile {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .infoTilesSection___ki3wI .info-tiles-section__tile {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 24px;
  }
}
.infoTile___1KyLF .porthole-img__container {
  background-color: #ffffff;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.infoTile___1KyLF .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
  width: 75%;
}
@media (min-width: 768px) {
  .infoTile___1KyLF .info-tile__cta {
    display: block !important;
    margin-top: 12px;
  }
}
.title___1TfaI {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 991px) {
  .title___1TfaI {
    max-width: 450px;
  }
}

.mainContainer___1TIq2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px 40px 80px;
}
@media (max-width: 767px) {
  .mainContainer___1TIq2 {
    padding: 40px 20px 52px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mainContainer___1TIq2 {
    text-align: center;
  }
}
.mobileImageContainer___2Oxv- {
  display: flex;
  flex-wrap: wrap;
}
.mobileItem___2XAyA {
  flex-basis: 50%;
}
.mobileATag___VjKFK {
  display: block;
}
.cta___2fMza {
  display: block;
}
@media (max-width: 767px) {
  .cta___2fMza {
    margin-top: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cta___2fMza {
    margin-top: 40px;
  }
}
.titleContainer___2SjJT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.title___15K48 {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 40px;
  color: #003b58;
}
@media (max-width: 767px) {
  .title___15K48 {
    text-align: center;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .title___15K48 {
    font-size: 32px;
    margin: 0 auto;
  }
}
.arrow___1MGdq {
  position: absolute;
  top: 50%;
}
.image___3eSBd {
  width: 100%;
}
@media (min-width: 768px) {
  .image___3eSBd {
    padding: 0 20px;
  }
}
.container___1QWMR {
  position: relative;
  max-width: 100%;
  width: 100%;
}
.carousel___2LobZ {
  overflow: hidden;
  min-height: 280px;
  display: flex;
}
@media (max-width: 767px) {
  .carousel___2LobZ {
    flex-wrap: wrap;
  }
}
.carousel___2LobZ .react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: unset;
}
.carousel___2LobZ .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.carousel___2LobZ .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 10;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.carousel___2LobZ .react-multiple-carousel__arrow--left {
  left: 0;
}
.carousel___2LobZ .react-multiple-carousel__arrow--right {
  right: 0;
}
.carousel___2LobZ .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .carousel___2LobZ .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .carousel___2LobZ .react-multi-carousel-track {
    overflow: visible !important;
  }
}
.carousel___2LobZ .react-multiple-carousel__arrow--left {
  left: -22px;
}
.carousel___2LobZ .react-multiple-carousel__arrow--right {
  right: -22px;
}
@media (min-width: 768px) {
  .onlyMobile___2a35d {
    display: none;
  }
}
@media (max-width: 767px) {
  .notMobile___H5LfA {
    display: none;
  }
}

.lightGrayBg___NLsDV {
  background-color: #f7f7f7;
}
.paleYellow___3DUxT {
  background-color: #ffebb1;
}

.infoTile___RMD1h .porthole-img__container {
  background-color: #f7f7f7;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.infoTile___RMD1h .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
  width: 75%;
}
@media (max-width: 767px) {
  .tiles___LTYEg .info-tile {
    align-items: center !important;
  }
}
.dek___1khP1 {
  margin: auto;
}

@media (min-width: 992px) {
  .hideDesktop___1huoA {
    display: none;
  }
}
.rose___yJBcC {
  color: #f87e7e;
}
@media (min-width: 992px) {
  .isPostAuthNav3Enabled___WE_01 {
    padding-left: 74px;
  }
}

.hero___DX1GN {
  background-size: cover;
  background-position: center;
  width: 100%;
}
@media (min-width: 992px) {
  .hero___DX1GN {
    min-height: 500px;
  }
}
@media (max-width: 991px) {
  .hero___DX1GN {
    background-image: none !important;
  }
}
.blockImage___3wRzt {
  height: 300px;
  background-size: cover;
  background-position: center top;
}
@media (min-width: 481px) {
  .blockImage___3wRzt {
    height: 390px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blockImage___3wRzt {
    background-position-x: right;
  }
}
@media (min-width: 992px) {
  .blockImage___3wRzt {
    display: none;
  }
}
.contents___1skgw {
  padding: 40px 20px 52px;
  margin: 0;
  height: 100%;
  max-height: 100%;
  text-align: center;
}
@media (min-width: 992px) {
  .contents___1skgw {
    padding: 75px 60px 0;
    text-align: left;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}
.title___3u1Uk {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  color: #003b58;
  margin: 0;
  font-size: 32px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .title___3u1Uk {
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .title___3u1Uk {
    font-size: 56px;
  }
}
.caption___1nqrW {
  font-weight: 400;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
}
.bulletedList___3zush {
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .bulletedList___3zush {
    align-items: center;
    text-align: left;
  }
}
.bulletedList___3zush ul.bulleted-list {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .bulletedList___3zush ul.bulleted-list {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.bulletedList___3zush ul.bulleted-list li {
  margin-bottom: 4px;
}
.buttons___-rK_8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .buttons___-rK_8 {
    flex-direction: row;
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .buttons___-rK_8 {
    justify-content: flex-start;
  }
}
.buttons___-rK_8 .v2-button {
  max-width: 335px;
}
@media (max-width: 767px) {
  .buttons___-rK_8 .v2-button {
    width: 100%;
  }
}
.buttons___-rK_8 .v2-button + .v2-button {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .buttons___-rK_8 .v2-button + .v2-button {
    margin-top: 0;
    margin-left: 1rem;
  }
}
@media (max-width: 991px) {
  .borderBottom___35ZRf {
    border-bottom: solid 1px #c5d1d7;
  }
}

.section-title___3ggZK {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 32px;
  color: #003b58;
  line-height: 1.1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-title___3ggZK {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}
@media (max-width: 991px) {
  .section-title___3ggZK {
    max-width: 455px;
  }
}
@media (min-width: 768px) {
  .section-title___3ggZK {
    font-size: 40px;
    line-height: 48px;
  }
}
@media (min-width: 992px) {
  .cashContainer___10LHj {
    padding-left: 74px;
  }
}
.cashHero___3ru8D.pngHero___1_4Cu {
  background-size: 54%;
  background-position: top right;
}
@media (min-width: 992px) {
  .cashHero___3ru8D.pngHero___1_4Cu .hero__DesktopContents {
    width: 44%;
  }
}
@media (min-width: 1200px) {
  .cashHero___3ru8D.pngHero___1_4Cu {
    background-size: 665px;
    background-position-x: calc(50vw - 64px);
  }
  .cashHero___3ru8D.pngHero___1_4Cu .hero__DesktopContents {
    width: 500px;
  }
}
.cashHero___3ru8D {
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cashHero___3ru8D {
    padding-bottom: 80px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .cashHero___3ru8D {
    padding-bottom: 60px;
  }
}
.cashHero___3ru8D > .hero__notDesktop {
  position: absolute;
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center 205px;
  width: 100%;
  height: 100%;
  z-index: 0;
}
@media (max-width: 768px) {
  .cashHero___3ru8D {
    height: auto;
  }
}
@media (max-width: 991px) {
  .cashHero___3ru8D {
    height: 550px;
  }
  .cashHero___3ru8D div:nth-of-type(2) {
    z-index: 10;
  }
  .cashHero___3ru8D .hero__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 420px;
  }
  .cashHero___3ru8D .hero__DesktopContents > :first-child {
    max-width: 10em;
    margin: 0 auto;
  }
}
.cashHeroDek___3V3I8 {
  margin-top: 20px;
}
@media (max-width: 991px) {
  .cashHeroDek___3V3I8 {
    margin: 20px auto 0;
    max-width: none;
  }
}
.fund-section___1pMwt {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fund-section___1pMwt > .cta-container____noUq {
  background-color: #f7f7f7;
  padding-top: 30px;
  text-align: center;
}
.fund-title___LT1a9 {
  margin-bottom: 14px;
}
.how-section___1u0-X {
  background-color: #f7f7f7;
}
@media (min-width: 768px) and (max-width: 991px) {
  .how-section___1u0-X .ctaSection--tablet > .ctaSection__title {
    margin: 0 auto 20px;
  }
}
.text-section___1eEL3 {
  background-color: #f7f7f7;
  margin: 0 auto;
  padding: 0 20px 20px;
  text-align: center;
  width: 100%;
}
@media (max-width: 991px) {
  .text-section___1eEL3 {
    padding: 40px 20px 0;
  }
}
.text-section___1eEL3 .body___180IZ {
  color: #21201f;
  font-size: 20px;
  font-weight: 400;
}
.section-image-1___1Zsq8,
.section-image-2___2AHaw,
.section-image-3___1gQJ8 {
  height: 380px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 472px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-image-1___1Zsq8,
  .section-image-2___2AHaw,
  .section-image-3___1gQJ8 {
    height: 340px;
    width: calc(100vw - 120px);
  }
}
@media (max-width: 767px) {
  .section-image-1___1Zsq8,
  .section-image-2___2AHaw,
  .section-image-3___1gQJ8 {
    height: 300px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .section-image-1___1Zsq8,
  .section-image-2___2AHaw,
  .section-image-3___1gQJ8 {
    max-width: 412px;
  }
}
.cta-container____noUq {
  background-color: #f7f7f7;
  text-align: center;
  padding: 40px 0;
}
.cashHero___3ru8D > .hero__notDesktop {
  background-size: 275px;
  background-position: center 225px;
}

.section___2UWRh {
  background-color: #f7f7f7;
  text-align: center;
}
.section___2UWRh > .sectionTitle___370VC {
  margin-bottom: 14px;
}
.section___2UWRh > .dek___1F3D_ {
  margin: 0 auto 30px;
  max-width: 706px;
  font-family: 'circular', helvetica, sans-serif;
  color: #505050;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
.carousel___3JrtS {
  overflow: hidden;
  display: flex;
}
@media (max-width: 767px) {
  .carousel___3JrtS {
    flex-wrap: wrap;
  }
}
.carousel___3JrtS .arrow___Whhs0 {
  position: absolute;
  top: 50%;
  top: calc(50% - 30px);
}
.carousel___3JrtS .item___9Aycz {
  width: 100%;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .carousel___3JrtS .item___9Aycz {
    max-height: 300px;
  }
}
.carousel___3JrtS .imageContainer___2iVOU {
  height: 100%;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.carousel___3JrtS .image___19liO {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  max-width: 100%;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  background-color: #fcfcfc;
}
.carousel___3JrtS .portrait___2gcmV {
  margin-right: 20px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .carousel___3JrtS .portrait___2gcmV {
    margin: 0;
  }
}
.carousel___3JrtS .landscape___1tTuN {
  width: 100%;
  max-width: 315px;
}
.carousel___3JrtS .react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: unset;
  position: relative;
}
.carousel___3JrtS .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  height: 100%;
}
.carousel___3JrtS .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 10;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.carousel___3JrtS .react-multiple-carousel__arrow--left {
  left: -20px;
}
@media (max-width: 991px) {
  .carousel___3JrtS .react-multiple-carousel__arrow--left {
    left: -10px;
  }
}
.carousel___3JrtS .react-multiple-carousel__arrow--right {
  right: -20px;
}
@media (max-width: 991px) {
  .carousel___3JrtS .react-multiple-carousel__arrow--right {
    right: -10px;
  }
}
.carousel___3JrtS .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .carousel___3JrtS .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .carousel___3JrtS .react-multi-carousel-track {
    overflow: visible !important;
  }
}
.tileContent___j8Flw {
  width: 100%;
  padding: 5%;
}
.tileImg___2x1g5 {
  width: 100%;
  display: block;
}
.tileTextbox___1ccsk {
  background: #ffffff;
  text-align: left;
  padding: 7% 4%;
  height: 95px;
}
.tileTitle___15PQM {
  margin-bottom: 0;
  font-weight: 600;
}
.container___xYuqW {
  position: relative;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .container___xYuqW {
    padding: 0 40px;
  }
}
.arrows___9190q {
  top: 40%;
}
.rightArrow___1k7Mu {
  right: 0;
}
.leftArrow___16hlx {
  left: 0;
}
.ctaContainer___249ap {
  padding-top: 30px;
}

.weddingPhoto___3FfvI {
  cursor: pointer;
  transition: transform 0.5s ease-out;
}
.weddingPhoto___3FfvI .uploadcare--widget {
  display: none;
}
.weddingPhoto___3FfvI .imageContainer___c9hgI {
  position: relative;
}
.weddingPhoto___3FfvI .profileCompletedLogo___3MGGY {
  width: 35px;
  position: absolute;
  right: -10px;
  top: -25px;
}
.weddingPhoto___3FfvI .weddingPhotoImg___2Aps0 {
  width: 88px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .weddingPhoto___3FfvI .weddingPhotoImg___2Aps0 {
    width: 80px;
  }
}
.weddingPhoto___3FfvI .weddingPhotoImg___2Aps0.default___3yg93 {
  border: 1px #99c8df dashed;
}
.weddingPhoto___3FfvI .weddingPhotoImg___2Aps0.default___3yg93:hover {
  border-color: #0075ae;
}
.weddingPhoto___3FfvI:hover {
  transform: scale(1.2);
}

.container___XsDNo {
  width: 100%;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .container___XsDNo {
    display: inline-flex;
    align-items: center;
  }
}
.headerText___2UmAH {
  font-size: 32px;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .headerText___2UmAH {
    font-size: 24px;
  }
}
.tagRow___NHjYE {
  display: flex;
}
.tagRow___NHjYE a {
  display: inline-flex;
  align-items: center;
}

.card___1mFoR {
  display: flex;
}
@media (max-width: 767px) {
  .card___1mFoR {
    flex-direction: column;
  }
}
.card___1mFoR:not(.completed___37d-x):hover {
  text-transform: capitalize;
}
.card___1mFoR:not(.completed___37d-x):hover .icon___1NMV4 {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.cta___2YTlX {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .iconWrapper___tNaZR {
    margin-right: 12px;
  }
}
.iconWrapper___tNaZR img {
  border-radius: 50%;
}
@media (max-width: 767px) {
  .iconWrapper___tNaZR img {
    width: 28px;
  }
}
.ctaText___2W-Ew {
  text-decoration-line: line-through;
  color: #767676;
}

.container___3mtwd {
  display: flex;
}
@media (min-width: 768px) {
  .container___3mtwd {
    max-width: 1200px;
    justify-content: space-between;
    padding: 26px 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container___3mtwd {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .container___3mtwd {
    overflow-x: scroll;
    width: 750px;
    white-space: normal;
    padding: 2px;
  }
}
.cardContainer___2gN-4 {
  width: 20%;
}
@media (max-width: 767px) {
  .cardContainer___2gN-4 {
    background: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 4px;
    width: 140px;
    height: 100px;
    padding-top: 12px;
    margin-right: 12px;
  }
}
.divider___Vvtwi {
  border-left: 1px solid #eee;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .divider___Vvtwi {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (max-width: 767px) {
  .divider___Vvtwi {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.profileSectionContainer___39483 {
  position: relative;
  padding-top: 36px;
  background-image: url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/background-5477025247b18cc5947014d10cdb9834.svg);
  background-size: cover;
  padding-bottom: 40px;
  height: 100%;
}
@media (max-width: 767px) {
  .profileSectionContainer___39483 {
    padding-bottom: 20px;
  }
}
.profileSectionContainer___39483.completed____ZJBE {
  padding-bottom: 0;
}
.profileSection___j0CGG {
  position: relative;
  z-index: 2;
}
.profile___2lgQ3 {
  display: flex;
  justify-content: space-between;
}
.profile___2lgQ3 .weddingInfo___2d_M3 {
  display: flex;
  flex-grow: 2;
}
@media (max-width: 767px) {
  .profile___2lgQ3 {
    flex-direction: column;
  }
  .profile___2lgQ3 .weddingInfo___2d_M3 {
    flex-direction: column;
  }
}
.profile___2lgQ3 .inlineWeddingInfo___2NlRT {
  display: flex;
  width: 100%;
  margin-bottom: 22px;
}
.tasks___2XDkm {
  margin-top: 5px;
}
@media (max-width: 767px) {
  .tasks___2XDkm {
    overflow-x: scroll;
  }
}
.backgroundConfetti___3zIOA {
  position: absolute;
}
.backgroundConfetti___3zIOA.top___1ctr0 {
  top: 15px;
  left: 0;
}
.backgroundConfetti___3zIOA.bottom___2smx- {
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .backgroundConfetti___3zIOA.bottom___2smx- {
    display: none;
  }
}
@media (max-width: 767px) {
  .notMobile___3gUe_ {
    display: none;
  }
}
@media (min-width: 768px) {
  .mobile___3xicP {
    display: none;
  }
}

.zola-ui.chevron-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.zola-ui.chevron-button .chevron-button_icon {
  font-size: 18px;
  position: relative;
}
.zola-ui.chevron-button .chevron-button_icon--left {
  left: -1px;
}
.zola-ui.chevron-button .chevron-button_icon--right {
  left: 1px;
}
.zola-ui.chevron-button .chevron-button_icon--up {
  top: 1px;
}
.zola-ui.chevron-button.chevron-button--primary {
  background-color: #0e0e0e;
  color: #ffffff;
}
.zola-ui.chevron-button.chevron-button--primary:active,
.zola-ui.chevron-button.chevron-button--primary.active,
.zola-ui.chevron-button.chevron-button--primary:hover,
.zola-ui.chevron-button.chevron-button--primary.hover {
  background: #4a4a4a;
  color: #ffffff;
}
.zola-ui.chevron-button.chevron-button--secondary {
  background-color: #ffffff;
  border: 1px solid #0e0e0e;
  color: #0e0e0e;
}
.zola-ui.chevron-button.chevron-button--secondary:active,
.zola-ui.chevron-button.chevron-button--secondary.active,
.zola-ui.chevron-button.chevron-button--secondary:hover,
.zola-ui.chevron-button.chevron-button--secondary.hover {
  background-color: #f3f3f3;
  color: #0e0e0e;
  border-color: #0e0e0e;
}
.zola-ui.chevron-button.chevron-button--tertiary {
  background-color: transparent;
  color: #0e0e0e;
}
.zola-ui.chevron-button.chevron-button--tertiary:active,
.zola-ui.chevron-button.chevron-button--tertiary.active,
.zola-ui.chevron-button.chevron-button--tertiary:hover,
.zola-ui.chevron-button.chevron-button--tertiary.hover {
  color: #4a4a4a;
  box-shadow: none;
}

.zui__h1,
.zui__h2,
.zui__h3,
.zui__h4,
.zui__h5,
.zui__h6 {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
}
.zui__h1 {
  font-size: 56px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h1 {
    font-size: 40px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h1 {
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h2 {
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h2 {
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h2 {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h3 {
  font-size: 32px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h3 {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h4 {
  font-size: 24px;
}
@media (max-width: 991px) {
  .zui__h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
.zui__h5 {
  font-size: 20px;
}
.zui__h6 {
  font-size: 16px;
}
.body-copy {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.body-copy.body-copy--align-center {
  text-align: center;
}
.body-copy.body-copy--light-gray {
  color: var(--text-tertiary, #767676);
}
.body-copy.body-copy--primary-gray {
  color: var(--text-secondary, #505050);
}
.body-copy.body-copy--normal-weight {
  font-weight: 400;
}
.separator--one {
  margin-left: 1em;
}
.drawer__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.drawer__content .faq-section-item {
  flex-basis: 100%;
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .drawer__content .faq-section-item {
    flex-basis: 48%;
  }
}
.drawer__content .drawer.fade-bottom {
  position: relative;
}
.faq-section-container-v2 {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.faq-section-container-v2 span {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 56px;
}
.faq-section-container-v2 .arrow-button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.faq-section-container-v2 .faq-section-v2__title.zui__h2 {
  text-align: left;
  font-size: 32px;
  margin-bottom: 20px;
  color: var(--text-primary, #21201f);
}
@media (min-width: 768px) {
  .faq-section-container-v2 .faq-section-v2__title.zui__h2 {
    margin-bottom: 32px;
  }
}
@media (min-width: 992px) {
  .faq-section-container-v2 .faq-section-v2__title.zui__h2 {
    font-size: 40px;
  }
}
.faq-section-container-v2 .faq-section-item__description {
  line-height: 32px;
}
@media (max-width: 991px) {
  .faq-section-container-v2 .faq-section-item__description {
    line-height: 28px;
  }
}

.faq-section-item {
  display: flex;
  flex-direction: column;
}
.faq-section-item span {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.faq-section-item__title.zui__h3 {
  color: var(--text-primary, #21201f);
  text-align: left;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 28px;
}
@media (min-width: 992px) {
  .faq-section-item__title.zui__h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
.faq-section-item__title.zui__h3 a {
  font-family: inherit;
  font-weight: 500;
}
.faq-section-item__description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--text-tertiary, #767676);
}
.faq-section-item__description--link {
  font-weight: 600;
  cursor: pointer;
}

.zui__h1,
.zui__h2,
.zui__h3,
.zui__h4,
.zui__h5,
.zui__h6 {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
}
.zui__h1 {
  font-size: 56px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h1 {
    font-size: 40px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h1 {
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h2 {
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h2 {
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h2 {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h3 {
  font-size: 32px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .zui__h3 {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .zui__h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 0;
  }
}
.zui__h4 {
  font-size: 24px;
}
@media (max-width: 991px) {
  .zui__h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
.zui__h5 {
  font-size: 20px;
}
.zui__h6 {
  font-size: 16px;
}
.body-copy {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.body-copy.body-copy--align-center {
  text-align: center;
}
.body-copy.body-copy--light-gray {
  color: var(--text-tertiary, #767676);
}
.body-copy.body-copy--primary-gray {
  color: var(--text-secondary, #505050);
}
.body-copy.body-copy--normal-weight {
  font-weight: 400;
}
.separator--one {
  margin-left: 1em;
}
.ctaSection {
  height: 410px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .ctaSection {
    height: 543px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ctaSection {
    display: none;
  }
}
@media (max-width: 767px) {
  .ctaSection {
    display: none;
  }
}
.ctaSection--light-blue-bg {
  background-color: #c9ddf8;
}
.ctaSection--soft-pink-bg {
  background-color: #fcd6d3;
}
.ctaSection--light-gray-bg {
  background-color: #f7f7f7;
}
.ctaSection--baby-blue-bg {
  background-color: #e3eaf6;
}
.ctaSection--registry-light-blue-bg {
  background-color: #c5e3ea;
}
.ctaSection--light-green-bg {
  background-color: #d7e4cf;
}
.ctaSection--humming-bird-bg {
  background-color: #eee2d0;
}
.ctaSection--mobile {
  padding: 32px 20px;
}
@media (min-width: 768px) {
  .ctaSection--mobile {
    display: none;
  }
}
.ctaSection--tablet {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 25px;
  align-items: flex-start;
  text-align: left;
  height: 100% !important;
}
@media (max-width: 767px), (min-width: 992px) {
  .ctaSection--tablet {
    display: none;
  }
}
.ctaSection--tablet__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.ctaSection--tablet__buttons.ctaSection--tablet__buttons--center {
  justify-content: center;
}
.ctaSection--mobile-border-bottom {
  border-bottom: solid 1px #c5d1d7;
}
.ctaSection__buttons {
  margin-top: 1rem;
}
.ctaSection__contents {
  margin-left: 3rem;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
}
@media (max-width: 480px) {
  .ctaSection__contents {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 30px;
  }
}
@media (min-width: 1200px) {
  .ctaSection__contents {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
h1.ctaSection__title,
h2.ctaSection__title,
h3.ctaSection__title,
h4.ctaSection__title,
h5.ctaSection__title,
h6.ctaSection__title {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
}
@media (max-width: 480px) {
  h1.ctaSection__title,
  h2.ctaSection__title,
  h3.ctaSection__title,
  h4.ctaSection__title,
  h5.ctaSection__title,
  h6.ctaSection__title {
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  h1.ctaSection__title {
    font-size: 40px;
  }
}
.ctaSection__title {
  color: #003b58;
}
.ctaSection__caption {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  color: #767676;
}
.ctaSection__title--smaller {
  font-size: 2.4rem;
}
.ctaSection__primary {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 480px) {
  .ctaSection__primary {
    justify-content: initial;
  }
}
.ctaSection__secondary {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.numbered-item {
  line-height: 32px;
}
.numbered-item + .numbered-item {
  margin-top: 24px;
}
@media (min-width: 992px) {
  .numbered-item + .numbered-item {
    margin-top: 40px;
  }
}
.numbered-item__title {
  display: inline;
  margin-bottom: 0;
  margin-top: 0;
}
.numbered-item__number {
  font-size: 24px;
  font-weight: 600;
}
.numbered-item__number-color--teal {
  color: #003b58;
}
.numbered-item__body:last-child {
  margin-bottom: 0;
}
.numbered-item__title-color--navy {
  color: #003b58;
}

.how-it-works-section {
  /* -- Styles for manipulating other components -- */
}
@media (min-width: 992px) {
  .how-it-works-section {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.how-it-works-section,
.how-it-works-section-mobile {
  background-color: #f7f7f7;
}
.how-it-works-section__image-container {
  position: relative;
}
@media (max-width: 991px) {
  .how-it-works-section__image-container {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .how-it-works-section__image {
    max-width: 570px;
    margin: 0 auto;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .how-it-works-section__image {
    max-width: 600px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .how-it-works-section__image {
    max-width: 100%;
  }
}
.how-it-works-section__modal-icon {
  font-size: 140px;
  color: #ffffff;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 100px);
  line-height: 100%;
}
.how-it-works-section__title.zui__h2 {
  margin: 0 auto 32px;
  text-align: center;
  color: #003b58;
  font-size: 32px;
}
@media (max-width: 767px) {
  .how-it-works-section__title.zui__h2 {
    max-width: 315px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .how-it-works-section__title.zui__h2 {
    max-width: 450px;
  }
}
@media (min-width: 992px) {
  .how-it-works-section__title.zui__h2 {
    margin-bottom: 40px;
    font-size: 40px;
  }
}
.how-it-works-section__list {
  max-width: 480px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .how-it-works-section__list {
    margin: 0 auto;
    max-width: 330px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .how-it-works-section__list {
    margin: 30px auto 0;
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .how-it-works-section__list {
    margin-bottom: 45px;
  }
}
.how-it-works-section .numbered-item__title {
  font-weight: 600;
  font-size: 20px;
}
@media (min-width: 992px) {
  .how-it-works-section .numbered-item__title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .how-it-works-section .body-copy {
    font-size: 16px;
  }
}
.how-it-works-section .cta-v2 {
  margin-top: 40px;
}
@media (max-width: 767px), (min-width: 992px) {
  .how-it-works-section .cta-v2 {
    margin-top: 52px;
    text-align: left;
  }
}
.how-it-works-section .ctaSection {
  height: 100%;
}
.how-it-works-section .ctaSection--tablet {
  height: 100%;
  padding: 0;
  align-items: center;
}
@media (min-width: 992px) {
  .how-it-works-section .ctaSection__primary {
    padding-right: 10px;
  }
}
.how-it-works-section .ctaSection__secondary-contents {
  align-self: center;
}
@media (max-width: 991px) {
  .how-it-works-section .ctaSection__secondary-contents {
    text-align: center;
  }
}
.how-it-works-section .ctaSection__title {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .how-it-works-section .ctaSection__title {
    max-width: 405px;
  }
}
@media (min-width: 768px) {
  .how-it-works-section-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .how-it-works-section-regular {
    display: none;
  }
}

.zola-ui.drawer .drawer__expand-button-container {
  text-align: center;
  min-height: 38px;
}
.zola-ui.drawer .drawer__content {
  overflow: hidden;
  transition: max-height 0.2s ease;
}
.zola-ui.drawer.fade-bottom {
  position: relative;
}
.zola-ui.drawer.fade-bottom .drawer__content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 38px;
  height: 100px;
  background-color: transparent;
  pointer-events: none;
}
.zola-ui.drawer.fade-bottom .drawer__content::after {
  background-image: linear-gradient(rgba(252, 252, 252, 0), #ffffff 90%);
}
.zola-ui.drawer.fade-bottom--secondary-gray .drawer__content::after {
  background-image: linear-gradient(rgba(252, 252, 252, 0), #f7f7f7 90%);
}
.zola-ui.drawer.fade-bottom--background-gray .drawer__content::after {
  background-image: linear-gradient(rgba(252, 252, 252, 0), #fcfcfc 90%);
}

.outer___1204w {
  position: sticky;
  bottom: 0;
}
@media (max-width: 991px) {
  .outer___1204w {
    margin: 0 20px;
  }
}
.container___3Br8a {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  padding: 20px 0;
}
@media (min-width: 992px) {
  .container___3Br8a {
    max-width: 706px;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: 100% inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  min-width: 320px;
  overflow-x: auto;
}
body {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  color: var(--text-primary, #0e0e0e);
  background-color: var(--background-screen, #ffffff);
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
}
a {
  color: var(--text-primary, #0e0e0e);
  text-decoration: underline;
}
a:hover,
a:focus {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
[role='button'] {
  cursor: pointer;
}
.zui-svg-icon___3YVrI {
  width: auto;
}
.font-weight-light___ItXLS {
  font-weight: 100;
}
.font-weight-regular___1dMIU {
  font-weight: 400;
}
.font-weight-bold___1-Xu1 {
  font-weight: 700;
}
.font-family-serif___VC7Cj {
  font-family: 'new-spirit', 'helvetica-bold', serif;
}
.font-family-script___1YLZP {
  font-family: 'Sakura Regular', 'Georgia', serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1___2h10X,
.h2___36C-2,
.h3___3lvNX,
.h4___23oCL,
.h5___3gwX2,
.h6___3wpzP,
.h7___1R-P7 {
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.1;
  color: #21201f;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1___2h10X small,
.h2___36C-2 small,
.h3___3lvNX small,
.h4___23oCL small,
.h5___3gwX2 small,
.h6___3wpzP small,
.h7___1R-P7 small,
h1 .small___3lRrs,
h2 .small___3lRrs,
h3 .small___3lRrs,
h4 .small___3lRrs,
h5 .small___3lRrs,
h6 .small___3lRrs,
.h1___2h10X .small___3lRrs,
.h2___36C-2 .small___3lRrs,
.h3___3lvNX .small___3lRrs,
.h4___23oCL .small___3lRrs,
.h5___3gwX2 .small___3lRrs,
.h6___3wpzP .small___3lRrs,
.h7___1R-P7 .small___3lRrs {
  font-weight: 400;
  line-height: 1;
}
h1.font-family-script___1YLZP,
h2.font-family-script___1YLZP,
h3.font-family-script___1YLZP,
.h1___2h10X.font-family-script___1YLZP,
.h2___36C-2.font-family-script___1YLZP,
.h3___3lvNX.font-family-script___1YLZP,
h1 > .font-family-script___1YLZP,
h2 > .font-family-script___1YLZP,
h3 > .font-family-script___1YLZP,
.h1___2h10X > .font-family-script___1YLZP,
.h2___36C-2 > .font-family-script___1YLZP,
.h3___3lvNX > .font-family-script___1YLZP {
  font-size: 150%;
}
h1,
.h1___2h10X {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 32px;
  font-weight: 500;
}
h2,
.h2___36C-2 {
  font-size: 30px;
  letter-spacing: 0;
  font-weight: 400;
}
h3,
.h3___3lvNX {
  font-size: 24px;
  letter-spacing: 0.2px;
  font-weight: 400;
}
h4,
.h4___23oCL {
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 400;
}
h5,
.h5___3gwX2 {
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
h6,
.h6___3wpzP {
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
p,
.p___1aX-S {
  margin: 0 0 11px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary, #0e0e0e);
}
p small,
.p___1aX-S small,
p .small___3lRrs,
.p___1aX-S .small___3lRrs {
  font-size: 12px;
}
@media (max-width: 992px) {
  p.responsive___3XeDk,
  .p___1aX-S.responsive___3XeDk {
    font-size: 18px;
  }
  p.responsive___3XeDk small,
  .p___1aX-S.responsive___3XeDk small,
  p.responsive___3XeDk .small___3lRrs,
  .p___1aX-S.responsive___3XeDk .small___3lRrs {
    font-size: 14px;
  }
}
.mobile-text___1FJNW p,
.mobile-text___1FJNW .p___1aX-S {
  font-size: 18px;
}
.mobile-text___1FJNW small,
.mobile-text___1FJNW .small___3lRrs {
  font-size: 14px;
}
 {
  /**
 * Core Zola Fonts
 * must be in src root so build font-path matches src font-path
 */
  /* Custom Circular font for body - was added to the zola-web-assets bucket */
  /**
 * Zola Icons
 * must be in src root so build font-path matches src font-path
 */
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face { 
    font-family: 'zolaicons';
    src: url('../font/zolaicons.svg?30092283#zolaicons') format('svg');
  }
}
*/
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
@font-face {
  font-family: 'Sakura Regular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/SakuraRegular.woff');
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'zola-ui-icons';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.eot?46588171');
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.eot?46588171#iefix') format('embedded-opentype'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.woff2?46588171') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.woff?46588171') format('woff'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.ttf?46588171') format('truetype'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.svg?46588171#zolaicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='zola-ui-icon-']::before,
[class*=' zola-ui-icon-']::before {
  font-family: 'zola-ui-icons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.zola-ui-icon-email::before {
  content: '\E800';
}
.zola-ui-icon-move-1::before {
  content: '\E801';
}
.zola-ui-icon-chevron-up::before {
  content: '\E802';
}
.zola-ui-icon-mobile::before {
  content: '\E803';
}
.zola-ui-icon-desktop::before {
  content: '\E804';
}
.zola-ui-icon-arrow-down::before {
  content: '\E805';
}
.zola-ui-icon-arrow-up::before {
  content: '\E806';
}
.zola-ui-icon-bank::before {
  content: '\E807';
}
.zola-ui-icon-call::before {
  content: '\E808';
}
.zola-ui-icon-chat::before {
  content: '\E809';
}
.zola-ui-icon-train::before {
  content: '\E80A';
}
.zola-ui-icon-shuttle::before {
  content: '\E80B';
}
.zola-ui-icon-warning-sign::before {
  content: '\E80C';
}
.zola-ui-icon-car-rental::before {
  content: '\E80D';
}
.zola-ui-icon-clock::before {
  content: '\E80E';
}
.zola-ui-icon-house-rental::before {
  content: '\E80F';
}
.zola-ui-icon-collection::before {
  content: '\E810';
}
.zola-ui-icon-credit-card::before {
  content: '\E811';
}
.zola-ui-icon-design::before {
  content: '\E812';
}
.zola-ui-icon-exchange::before {
  content: '\E813';
}
.zola-ui-icon-gift::before {
  content: '\E814';
}
.zola-ui-icon-headset::before {
  content: '\E815';
}
.zola-ui-icon-calendar::before {
  content: '\E816';
}
.zola-ui-icon-invitefriend::before {
  content: '\E817';
}
.zola-ui-icon-folder::before {
  content: '\E818';
}
.zola-ui-icon-cake::before {
  content: '\E819';
}
.zola-ui-icon-visible::before {
  content: '\E81A';
}
.zola-ui-icon-gear-1::before {
  content: '\E81B';
}
.zola-ui-icon-store::before {
  content: '\E81C';
}
.zola-ui-icon-star::before {
  content: '\E81D';
}
.zola-ui-icon-smartphone::before {
  content: '\E81E';
}
.zola-ui-icon-price-tag::before {
  content: '\E81F';
}
.zola-ui-icon-play::before {
  content: '\E820';
}
.zola-ui-icon-piggybank::before {
  content: '\E821';
}
.zola-ui-icon-package::before {
  content: '\E822';
}
.zola-ui-icon-pencil::before {
  content: '\E823';
}
.zola-ui-icon-notes::before {
  content: '\E824';
}
.zola-ui-icon-newspaper::before {
  content: '\E825';
}
.zola-ui-icon-magnifying-glass::before {
  content: '\E826';
}
.zola-ui-icon-info::before {
  content: '\E827';
}
.zola-ui-icon-heart::before {
  content: '\E828';
}
.zola-ui-icon-lock::before {
  content: '\E829';
}
.zola-ui-icon-link::before {
  content: '\E82A';
}
.zola-ui-icon-globe::before {
  content: '\E82B';
}
.zola-ui-icon-group::before {
  content: '\E82C';
}
.zola-ui-icon-flight::before {
  content: '\E82D';
}
.zola-ui-icon-terminal::before {
  content: '\E82E';
}
.zola-ui-icon-trash::before {
  content: '\E82F';
}
.zola-ui-icon-truck::before {
  content: '\E830';
}
.zola-ui-icon-checkmark::before {
  content: '\E831';
}
.zola-ui-icon-cart::before {
  content: '\E832';
}
.zola-ui-icon-pricematch-1::before {
  content: '\E833';
}
.zola-ui-icon-play-circled::before {
  content: '\E834';
}
.zola-ui-icon-hotel::before {
  content: '\E835';
}
.zola-ui-icon-chevron-down::before {
  content: '\E836';
}
.zola-ui-icon-chevron-left::before {
  content: '\E837';
}
.zola-ui-icon-chevron-right::before {
  content: '\E838';
}
.zola-ui-icon-clock-1::before {
  content: '\E839';
}
.zola-ui-icon-twitter::before {
  content: '\E83A';
}
.zola-ui-icon-facebook::before {
  content: '\E83B';
}
.zola-ui-icon-gplus::before {
  content: '\E83C';
}
.zola-ui-icon-pinterest-circled::before {
  content: '\E83D';
}
.zola-ui-icon-instagram::before {
  content: '\E83E';
}
.zola-ui-icon-videocam::before {
  content: '\E83F';
}
.zola-ui-icon-close::before {
  content: '\E840';
}
.zola-ui-icon-play-2::before {
  content: '\E841';
}
.zola-ui-icon-facebook-square::before {
  content: '\E842';
}
.zola-ui-icon-download-alt::before {
  content: '\E843';
}
.zola-ui-icon-gear::before {
  content: '\E844';
}
.zola-ui-icon-poi::before {
  content: '\E845';
}
.zola-ui-icon-invite-partner::before {
  content: '\E846';
}
.zola-ui-icon-overflow-dots::before {
  content: '\E847';
}
.zola-ui-icon-link-arrow::before {
  content: '\E848';
}
.zola-ui-icon-rsvp::before {
  content: '\E84B';
}
.zola-ui-icon-wedding_party::before {
  content: '\E84D';
}
.zola-ui-icon-home::before {
  content: '\E84E';
}
.zola-ui-icon-travel::before {
  content: '\E84F';
}
.zola-ui-icon-note::before {
  content: '\E850';
}
.zola-ui-icon-faq::before {
  content: '\E853';
}
.zola-ui-icon-couple::before {
  content: '\E855';
}
.zola-ui-icon-family::before {
  content: '\E856';
}
.zola-ui-icon-individual::before {
  content: '\E857';
}
.zola-ui-icon-photo::before {
  content: '\E858';
}
.zola-ui-icon-registry::before {
  content: '\E859';
}
.zola-ui-icon-event::before {
  content: '\E85A';
}
.zola-ui-icon-filter::before {
  content: '\E85B';
}
.zola-ui-icon-print::before {
  content: '\E85C';
}
.zola-ui-icon-bulb::before {
  content: '\E85D';
}
.zola-ui-icon-chevron-left-bold::before {
  content: '\E862';
}
.zola-ui-icon-chevron-right-bold::before {
  content: '\E863';
}
.zola-ui-icon-link-ext-v2::before {
  content: '\E864';
}
.zola-ui-icon-reorder::before {
  content: '\E884';
}
.zola-ui-icon-profile-photo::before {
  content: '\E88E';
}
.zola-ui-icon-photo-upload::before {
  content: '\E88F';
}
.zola-ui-icon-plus::before {
  content: '\EA0A';
}
.zola-ui-icon-minus::before {
  content: '\EA0B';
}
.zola-ui-icon-share::before {
  content: '\EA7D';
}
.zola-ui-icon-move::before {
  content: '\F047';
}
.zola-ui-icon-link-ext::before {
  content: '\F08E';
}
.zola-ui-icon-plane::before {
  content: '\E865';
}
.zola-ui-icon-two-hearts::before {
  content: '\E866';
}
.zola-ui-icon-two-rings::before {
  content: '\E867';
}
.zola-ui-icon-group-alt::before {
  content: '\E868';
}
.zola-ui-icon-gift-alt::before {
  content: '\E86C';
}
.zola-ui-icon-dollar-sign::before {
  content: '\E849';
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
/**
* NOTE:  Uncomment this file for local zola-ui development
* This file is created by `npm run zola-ui` for local zola-ui development and
* integration with web-preauth. @font-face are stripped out to make it work.
*/

.button___2_Nwf {
  height: 56px !important;
  background-color: #1877f2 !important;
  color: #ffffff !important;
  margin-bottom: 8px !important;
  white-space: nowrap;
}
.button___2_Nwf i {
  margin-right: 4px;
}

.btn___HsLyC {
  border: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 1px !important;
  text-transform: none !important;
  height: 56px !important;
  width: 100%;
  overflow: hidden;
}
.btn___HsLyC > div {
  max-width: 100% !important;
}
.btn___HsLyC > div > div:first-of-type {
  top: -2px;
  left: 36px;
  /* Safari only override */
}
@supports (-webkit-hyphens: none) {
  .btn___HsLyC > div > div:first-of-type {
    top: 0;
    left: 0;
  }
  .btn___HsLyC > div > div:first-of-type > svg {
    width: 154px;
  }
}
.btn___HsLyC text {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 8px;
  letter-spacing: 36px;
  /* Safari only override */
}
@supports (-webkit-hyphens: none) {
  .btn___HsLyC text {
    font-size: 12px;
  }
}

.other___2cJs_ {
  border-top: 1px solid #d9d9d9;
  border: 0;
  height: 5px;
  margin-bottom: 24px;
  margin-top: 16px;
  overflow: visible;
  text-align: center;
}
.other___2cJs_::after {
  background: var(--background-screen, #ffffff);
  content: 'Or';
  padding: 0 4px;
  position: relative;
  top: -13px;
}
.password___2x0Co label {
  align-items: center;
  display: flex !important;
}
.password___2x0Co .label__link-desktop {
  margin-left: auto;
}
.footer___1m1w3 {
  padding: 20px 20px 0;
  text-align: center;
}
.footerText___3gwfl {
  color: var(--text-primary, #0e0e0e);
  font-size: 14px;
}
.footerText___3gwfl a {
  font-weight: 600 !important;
}
.footerText___3gwfl + .footerText___3gwfl {
  margin-top: 12px;
}
.forgot___2OrjM {
  display: block;
  margin-bottom: 12px;
}

.container___2uakN h1,
.containerWide___KJ9kQ h1 {
  font-family: var(--font-family-serif, 'new-spirit', 'helvetica-bold', serif);
  font-weight: var(--heading-font-family-serif-weight, 500);
}
.container___2uakN {
  margin: 19px;
  padding-bottom: 100px;
  position: relative;
  width: auto;
}
@media (min-width: 768px) {
  .container___2uakN {
    margin: 0 auto;
    padding-top: 40px;
    width: 365px;
  }
}
.containerWide___KJ9kQ {
  margin: 19px;
  padding-bottom: 40px;
  padding-top: 20px;
  position: relative;
  width: auto;
}
@media (min-width: 768px) {
  .containerWide___KJ9kQ {
    margin: 0 auto;
    width: 440px;
  }
}
.title___27KFg {
  color: var(--text-primary, #0e0e0e);
  font-family: var(--font-family-serif, 'new-spirit', 'helvetica-bold', serif);
  font-weight: var(--heading-font-family-serif-weight, 500);
  margin: 20px 0;
  text-align: center;
}
.field___20foF {
  margin-bottom: 20px;
}
.error___2jERA {
  color: #b20033;
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 12px;
  text-align: center;
}
.submit___3ONgs {
  margin: 20px 0 !important;
}
.textCenter___hb-ur {
  color: var(--text-primary, #0e0e0e);
  text-align: center;
}

.other___1sJ8j {
  overflow: visible;
  text-align: center;
  height: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid #d9d9d9;
}
.other___1sJ8j::after {
  background: #ffffff;
  content: 'Or';
  padding: 0 4px;
  position: relative;
  top: -13px;
}
.title___1ybaZ {
  margin: 20px;
  text-align: center;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 24px;
  color: var(--text-primary, #21201f);
  font-weight: 600;
}
@media (max-width: 767px) {
  .title___1ybaZ {
    margin: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .hr___2cwIw {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.password___23rlV label {
  display: flex !important;
  align-items: center;
}
.password___23rlV .label__link-desktop {
  margin-left: auto;
}
.footerHr___Rqo-P {
  margin: 40px -40px;
  border: 0;
  border-top: 1px solid #0e0e0e;
}
.footer___15uFA {
  padding: 20px 20px 0;
  text-align: center;
}
.footer___15uFA.alreadyHaveAccountFooter___W0xXB {
  padding: 0;
}
.footerText___1Oa3D {
  color: var(--text-secondary, #505050);
}
.footerText___1Oa3D + .footerText___1Oa3D {
  margin-top: 20px;
}
.formLabelRight___1TWzR {
  float: right;
  color: var(--text-secondary, #505050);
  font-weight: 700;
}
@media (min-width: 768px) {
  .externalLoginContainer___2csQC {
    display: flex;
    justify-content: space-between;
  }
  .externalLoginContainer___2csQC > * {
    width: 49%;
  }
  .externalLoginContainer___2csQC.flexColumns___3ma5Y {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .externalLoginContainer___2csQC {
    max-width: 320px;
    margin: auto;
  }
}
.formInputsContainer___1UfG8 {
  margin-top: 20px;
}
.signUpWithSocialLink___IE0dJ {
  margin-bottom: 40px;
}
.signUpWithEmailLink___5tqjC {
  text-align: center;
  margin-top: 40px;
}
.relativeContainer___1BoOC {
  position: relative;
}
.topRight___14CWq {
  position: absolute;
  top: 0;
  right: 0;
}
.ctaText___3DWae {
  text-transform: none !important;
}
.inquiryFlowAlreadyHaveAccountFooter___2zH-X {
  text-align: left;
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-primary, #0e0e0e);
}

.strikethrough___2lq-r .selector-label {
  text-decoration: line-through;
  color: #505050 !important;
}
.checklistItem___29ZwC {
  padding-bottom: 40px;
}
.checklistItem___29ZwC:last-child {
  padding-bottom: 0;
}
.checklistItem___29ZwC .selector-icon-wrapper {
  margin-top: 6px;
}
.checklistItem___29ZwC .selector-label {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #21201f;
}
.checklistItem___29ZwC .selector-dek {
  font-size: 20px !important;
  line-height: 28px !important;
  color: #505050 !important;
}

.marketingModule___1_-qG {
  border-bottom: 1px solid #b7b7b7;
  padding: 24px;
}
@media (min-width: 768px) {
  .marketingModule___1_-qG {
    padding: 40px;
  }
}
.marketingModule___1_-qG picture {
  display: flex;
}
.marketingModule___1_-qG picture img {
  border-radius: 16px;
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.container___Z4dDg {
  display: flex;
  overflow-x: auto;
  width: calc(100% + 20px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container___Z4dDg::-webkit-scrollbar {
  display: none;
}
@media (min-width: 1024px) {
  .container___Z4dDg {
    max-width: 960px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px 0;
    margin: auto;
  }
}
.container___Z4dDg::after {
  width: 20px;
  content: ' ';
  height: 52px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(252, 252, 252, 0) 100%);
  position: absolute;
  display: block;
  right: 0;
}
.chip___2QdoT {
  flex-shrink: 0;
  border-radius: 16px !important;
  border-color: #b7b7b7 !important;
  font-weight: 400 !important;
}
.chip___2QdoT + .chip___2QdoT {
  margin-left: 8px;
}
.activeChip____89mV {
  border-color: #0e0e0e !important;
  background-color: #f3f3f3 !important;
}
.spacer___389U- {
  padding-right: 20px;
}

.rangeSections___1wkvJ {
  width: 100%;
}
.checklistRange___1b7Zb {
  padding-top: 0;
  padding-bottom: 52px;
}
@media (min-width: 768px) {
  .checklistRange___1b7Zb {
    max-width: 706px;
    margin: 0 auto;
  }
}
.image___3YZgF img {
  margin: 20px 0 0;
  max-width: 100%;
}
.rangeTitle___2lF_T {
  font-size: 32px;
  line-height: 44px;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  color: #21201f;
  margin: 60px 0 20px;
}
.rangeDescription___2GB0n {
  margin-bottom: 20px;
}
.rangeDescription___2GB0n p {
  font-size: 20px;
  line-height: 32px;
  color: #21201f !important;
}
@media (min-width: 768px) {
  .rangeDescription___2GB0n {
    margin-bottom: 40px;
  }
}
.photoCredit___1QxYv {
  width: 100%;
  text-align: right;
  font-size: 14px;
  line-height: 22px;
  color: #767676;
  font-weight: 400;
}

.container___3MsH0 {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 32px;
}
@media (max-width: 767px) {
  .container___3MsH0 {
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .container___3MsH0 {
    background: right 0 / contain no-repeat url(https://d1tntvpcrzvon2.cloudfront.net/vpassets/_next/static/images/confetti_wave_bg_light-31ac4aa60d3322ed00a2b0cf5488f8f5.png);
  }
}
@media (max-width: 991px) {
  .container___3MsH0 {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .maxWidthContainer___1CGgM {
    max-width: 70%;
  }
}
.markdownStyleOverride___3DSUW p,
.markdownStyleOverride___3DSUW ul,
.markdownStyleOverride___3DSUW ol {
  font-size: 16px;
  line-height: 28px;
}

.container___B96jL {
  padding: 24px 20px 20px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .container___B96jL {
    padding-left: 31px;
    padding-right: 31px;
  }
}
@media (min-width: 992px) {
  .container___B96jL {
    padding-left: 38px;
    padding-right: 38px;
  }
}
@media (min-width: 1200px) {
  .container___B96jL {
    padding-left: 62px;
    padding-right: 62px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1440px) {
  .container___B96jL {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (min-width: 992px) {
  .container___B96jL.isPostAuthNav3Enabled___1hXmP {
    padding-left: 112px;
  }
}
@media (min-width: 1200px) {
  .container___B96jL.isPostAuthNav3Enabled___1hXmP {
    padding-left: 136px;
  }
}
@media (min-width: 1440px) {
  .container___B96jL.isPostAuthNav3Enabled___1hXmP {
    padding-left: 144px;
  }
}

.container___23XTq {
  background: #f8f8f8;
}
.header___SkNPJ {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #21201f;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .header___SkNPJ {
    font-size: 32px;
    line-height: 40px;
    max-width: 1300px;
  }
}
.faqs___8oj9u::after {
  content: ' ';
  width: 100%;
  height: 2px;
  background: #d9d9d9;
  display: block;
  margin-top: 20px;
}
.faqs___8oj9u h1,
.faqs___8oj9u h2,
.faqs___8oj9u h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #0075ae;
  border-top: 2px solid #d9d9d9;
  padding-top: 20px;
  padding-left: 54px;
}
.faqs___8oj9u p {
  font-size: 16px;
  line-height: 24px;
  padding-left: 54px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .faqs___8oj9u p {
    max-width: 50%;
  }
}
.faqs___8oj9u a {
  font-weight: 600;
}

.testimonial-card {
  width: 300px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1em;
  background-color: #ffffff;
  display: flex !important;
  flex-direction: column;
  height: 100%;
}
.testimonial-card h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}
.testimonial-card h4 {
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 700;
  color: var(--text-secondary, #505050);
  margin-bottom: 10px;
  font-size: 16px;
}
.testimonial-card__title--row {
  display: flex;
  flex-direction: row;
}
.testimonial-card__title--column {
  display: flex;
  flex-direction: column;
}
.testimonial-card__title-first-letter {
  background-color: #e5eef7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 20px;
  margin-right: 8px;
  font-weight: 600;
  color: #003b58;
}
.testimonial-card__title-first-letter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testimonial-card__star {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzJweCIgaGVpZ2h0PSI3MnB4IiB2aWV3Qm94PSIwIDAgNzIgNzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+U3RhcjwvdGl0bGU+CiAgICA8ZGVzYz5BIGZpbGxlZCBzdGFyLjwvZGVzYz4KICAgIDxnIGlkPSJTcGVjaWFsL1N0YXJfRmlsbGVkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNEOEQ4RDgiIHg9IjAiIHk9IjAiIHdpZHRoPSI3MiIgaGVpZ2h0PSI3MiI+PC9yZWN0PgogICAgICAgIDxwYXRoIGQ9Ik0yNS41NiwyMi42MzIgTDYuMjI5NjUxMzYsMjUuNDQxMjU5MSBMNi4wNzg3NzMxNiwyNS40Njg3MzM2IEM0LjU1MDA3MDc5LDI1LjgwNDc4NjkgMy45NzEyODUzOSwyNy43MzE3MzIyIDUuMTIxMjI0NjEsMjguODUyNjQ1OSBMMTkuMTA5LDQyLjQ4NyBMMTUuODA3NDQwNSw2MS43NDE0MzY3IEwxNS43ODczOTk2LDYxLjg4ODg1MTIgQzE1LjYzMDkyMzYsNjMuNDQ4OTA2OSAxNy4yODY1NDY5LDY0LjU5Nzc5NiAxOC43MDkzMzk0LDYzLjg0OTc4OTcgTDM2LDU0Ljc1OSBMNTMuMjkwNjYwNiw2My44NDk3ODk3IEw1My40MjQ2NjcxLDYzLjkxNDQwMzMgQzU0Ljg2MDAxNDUsNjQuNTQ1MzA0NSA1Ni40NjQyODg3LDYzLjMyNTczOTQgNTYuMTkyNTU5NSw2MS43NDE0MzY3IEw1Mi44OSw0Mi40ODcgTDY2Ljg3ODc3NTQsMjguODUyNjQ1OSBMNjYuOTg0Njg5MywyOC43NDE3MzQ2IEM2OC4wMjM5MDgzLDI3LjU3MTMxMzEgNjcuMzU5NTI1NiwyNS42NzIxODAxIDY1Ljc3MDM0ODYsMjUuNDQxMjU5MSBMNDYuNDM5LDIyLjYzMiBMMzcuNzkzNDcyMiw1LjExNDg2ODU4IEMzNy4wNTk4NDQ4LDMuNjI4Mzc3MTQgMzQuOTQwMTU1MiwzLjYyODM3NzE0IDM0LjIwNjUyNzgsNS4xMTQ4Njg1OCBMMjUuNTYsMjIuNjMyIFoiIGlkPSJTdGFyIiBmaWxsPSIjRkRCRjAwIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  padding-left: 1em !important;
  width: 1em;
  height: 1em;
  display: inline-block;
}
.testimonial-card__body {
  padding-top: 20px;
  margin-bottom: auto;
}
.testimonial-card__body-description {
  font-family: 'circular', helvetica, sans-serif !important;
}
.testimonial-card__action .cta-v2 {
  text-align: left;
  font-weight: 700;
}

.testimonials-hero {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #e3eaf6;
  height: 584px;
}
@media (max-width: 480px) {
  .testimonials-hero {
    height: 100%;
    padding: 25px 20px;
  }
}
.testimonials-hero__content {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.testimonials-hero::before,
.testimonials-hero::after {
  flex: 1;
  content: '';
}
.testimonials-hero__title.zui__h2 {
  margin-bottom: 1em;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  color: #003b58;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
}
@media (min-width: 992px) {
  .testimonials-hero__title.zui__h2 {
    font-size: 40px;
  }
}
.testimonials-hero__button-container {
  margin-top: 2em;
  text-align: center;
}
.testimonials-hero .slick-track {
  display: flex;
  align-items: stretch;
}
.testimonials-hero .slick-initialized .slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
  min-height: 100%;
  height: auto;
  display: flex;
}
.testimonials-hero .slick-initialized .slick-slide > div {
  width: 100%;
}

.testimonialsHeroContainer___3b6y6 {
  height: 100% !important;
  max-width: unset !important;
  background-color: #f9f9f9;
}
.testimonialsHeroContainer___3b6y6::before,
.testimonialsHeroContainer___3b6y6::after {
  display: none;
}
@media (max-width: 767px) {
  .testimonialsHeroContainer___3b6y6 {
    padding: 40px 20px 52px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonialsHeroContainer___3b6y6 {
    padding: 60px 20px 80px;
  }
}
.testimonialsHeroContainer___3b6y6 .testimonials-hero__content {
  max-width: 1300px;
  position: relative;
}
.testimonialsHeroContainer___3b6y6 .testimonial-card {
  margin: 0 auto;
  width: 95%;
  height: 100%;
  padding-left: 17px;
  padding-right: 15px;
}
@media (max-width: 1170px) {
  .testimonialsHeroContainer___3b6y6 .testimonial-card {
    width: 95%;
  }
}
.testimonialsHeroContainer___3b6y6 .testimonial-card__body {
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.testimonialsHeroContainer___3b6y6 .testimonial-card__body-header {
  margin-top: 10px;
}
.testimonialsHeroContainer___3b6y6 .testimonial-card__body-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.testimonialsHeroContainer___3b6y6 .testimonial-card__title--row {
  padding-left: 10px;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: unset;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.testimonialsHeroContainer___3b6y6 .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 10;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.testimonialsHeroContainer___3b6y6 .react-multiple-carousel__arrow--left {
  left: 0;
}
.testimonialsHeroContainer___3b6y6 .react-multiple-carousel__arrow--right {
  right: 0;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .testimonialsHeroContainer___3b6y6 .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .testimonialsHeroContainer___3b6y6 .react-multi-carousel-track {
    overflow: visible !important;
  }
}
.testimonialsHeroContainer___3b6y6 .react-multiple-carousel__arrow--left {
  left: -12px;
}
.testimonialsHeroContainer___3b6y6 .react-multiple-carousel__arrow--right {
  right: -12px;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-dot button:hover:active {
  background: #080808;
}
.testimonialsHeroContainer___3b6y6 .react-multi-carousel-dot--active button {
  background: #080808;
}

.strong___3w3LA {
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.serif___3Waxr {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-serif, 'new-spirit', 'helvetica-bold', serif);
  font-weight: var(--text-font-family-serif-weight, 500);
}

.appDownloadBannerMobile___cK9yN {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -1px;
  padding: 8px 16px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  font-family: -apple-system, system-ui, 'circular', helvetica, sans-serif;
  line-height: normal;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .appDownloadBannerMobile___cK9yN {
    display: none;
  }
}
.appDownloadBannerMobile___cK9yN.hidden___GWbhZ {
  display: none;
}
.appDownloadBannerMobile___cK9yN.shown___3_TVr {
  display: flex !important;
}
.hideButton___1jCZO {
  align-self: stretch;
  display: flex;
  align-items: center;
  margin: -8px;
  padding: 8px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.appIcon___24Bty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: #183b54;
}
.appIcon___24Bty .logo___1g23j {
  width: 22px;
}
.babyAppIcon___3SzZt {
  background-color: #495fe8;
}
.babyAppIcon___3SzZt .babyLogo___3sWLP {
  height: 18px;
  width: 22px;
}
.appInfo___150JS {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1px;
}
.appInfo___150JS .heading___2HPvf {
  color: #0e0e0e;
  font-size: 12px;
  font-weight: 700;
}
.appInfo___150JS .subheading___2903C {
  color: #757575;
  font-size: 12px;
}
.openButton___2V8hN {
  padding: 6px 14px;
  border: 0;
  border-radius: 100px;
  background-color: #007aff;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.vendorCircleIcon___10Zfk {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0f5c82;
  color: #ffffff;
}

.vendorsCategoryList___Ps56C {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;
  margin: 0;
  padding: 0;
}
.vendorsCategoryList___Ps56C li {
  list-style: none;
}
.vendorsCategoryList___Ps56C .link___1mSBs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.vendorsCategoryList___Ps56C .name___Lwqih {
  margin: 0;
  color: #0e0e0e;
  font-size: 14px;
  text-align: center;
  text-transform: none;
}
.vendorsCategoryList___Ps56C .new___19Mnv {
  position: absolute;
  top: -8px;
  left: 50%;
}

.vendorsCategoryTiles___2a38o {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  column-gap: 8px;
  row-gap: 16px;
}
@media (max-width: 767px) {
  .vendorsCategoryTiles___2a38o {
    grid-template-columns: repeat(2, 1fr);
  }
}
.vendorsCategoryTiles___2a38o .tileLink___1TBeX {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
  row-gap: 8px;
  max-width: 160px;
}
.vendorsCategoryTiles___2a38o .tileImage___cysfr {
  max-width: 100%;
  border-radius: 16px;
}
.vendorsCategoryTiles___2a38o .tileName___3IFNf {
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.inlineAccordion___2HwJe {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  row-gap: 24px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}
.inlineAccordion___2HwJe .item___1SrYp {
  margin-right: -1px;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe .item___1SrYp {
    padding: 16px 0;
    border-bottom: 1px solid #b7b7b7;
  }
}
.inlineAccordion___2HwJe .item___1SrYp .title___1d4zm {
  display: flex;
  padding: 0 24px;
  border-right: 1px solid #b7b7b7;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe .item___1SrYp .title___1d4zm {
    padding: 0;
    border-right: none;
  }
}
.inlineAccordion___2HwJe .item___1SrYp .button___375c- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  min-height: 24px;
  text-align: left;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe .item___1SrYp .buttonTitle___N-1o5 {
    font-size: 14px;
  }
}
.inlineAccordion___2HwJe .item___1SrYp .buttonIcon___2biLT {
  flex-shrink: 0;
  transition: transform 0.2s ease;
}
.inlineAccordion___2HwJe .item___1SrYp .content___1Mana {
  height: 0;
  padding: 0 24px;
  overflow: hidden;
  transition: height 0.5s;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe .item___1SrYp .content___1Mana {
    padding: 0;
  }
}
.inlineAccordion___2HwJe .item___1SrYp .contentCollapse___yl9nx {
  padding: 24px 0;
}
@media (max-width: 991px) {
  .inlineAccordion___2HwJe .item___1SrYp .contentCollapse___yl9nx {
    padding: 16px 0 0 0;
    font-size: 14px;
  }
}
.inlineAccordion___2HwJe .item___1SrYp.open___2VQI- .buttonIcon___2biLT {
  transform: rotate(180deg);
}
.inlineAccordion___2HwJe .item___1SrYp:last-child {
  border-bottom: none;
}
.inlineAccordion___2HwJe .item___1SrYp:last-child .title___1d4zm {
  border-right: none;
}

@keyframes slide-up___aBbAj {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.container___3pDaO {
  display: flex;
  border-top: 1px solid #d9d9d9;
  padding: 24px 0px;
  height: 96px;
  animation: slide-up___aBbAj 0.4s ease;
}
@media (max-width: 767px) {
  .container___3pDaO {
    padding-top: 15px;
    height: 80px;
    height: fit-content;
  }
}
@media (min-width: 768px) {
  .container___3pDaO {
    padding-top: 30px;
  }
}
.container___3pDaO .name___3E5wn,
.container___3pDaO .date___39R-h,
.container___3pDaO .nameLink___3NMwL,
.container___3pDaO > p {
  width: 100%;
  text-align: left;
}
@media (min-width: 480px) {
  .container___3pDaO .name___3E5wn,
  .container___3pDaO .date___39R-h,
  .container___3pDaO .nameLink___3NMwL,
  .container___3pDaO > p {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .container___3pDaO .name___3E5wn,
  .container___3pDaO .date___39R-h,
  .container___3pDaO .nameLink___3NMwL,
  .container___3pDaO > p {
    font-size: 16px;
  }
}
.container___3pDaO:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.result___m_8Oa {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 480px) {
  .result___m_8Oa {
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .result___m_8Oa {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .result___m_8Oa {
    flex-direction: column;
  }
}
.name___3E5wn {
  width: 45%;
  font-weight: 600;
  line-height: 24px;
  color: #21201f;
}
@media (min-width: 768px) and (max-width: 991px) {
  .name___3E5wn {
    display: none;
  }
}
@media (max-width: 767px) {
  .name___3E5wn {
    width: 100%;
    font-size: 20px;
  }
}
.name___3E5wn a {
  text-decoration: none;
}
@media (max-width: 767px), (min-width: 992px) {
  .nameLink___3NMwL {
    display: none;
  }
}
.date___39R-h {
  width: 30%;
  line-height: 24px;
  color: #505050;
}
@media (max-width: 767px) {
  .date___39R-h {
    width: 100%;
  }
}
.link___2RZu- {
  width: 17%;
}
@media (max-width: 991px) {
  .link___2RZu- {
    display: none;
  }
}

.container___2UapR {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0 15% 0;
  padding-top: 0;
  padding-bottom: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .container___2UapR {
    margin: 0 100px;
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .container___2UapR {
    margin: 0 20px;
    padding-top: 32px;
  }
}
.count___2ByuC {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .count___2ByuC {
    margin-bottom: 32px;
    text-align: left;
    width: 100%;
  }
}
.resultsContainer___2R8Cy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 514px;
  width: 100%;
}

.starRatings___2sIXb {
  display: flex;
}
.ratingsWrapper___1muhE {
  display: flex;
  align-items: center;
  gap: 4px;
}
.stars___2ds-Q {
  display: flex;
  align-items: center;
}
.starWrapper___2sl8k {
  display: flex;
  justify-content: center;
  align-items: center;
}
.starWrapper___2sl8k svg {
  flex-grow: 1;
  width: unset !important;
  height: unset !important;
}
.starOutlinePadding___ZmCi0 {
  padding-left: 3%;
  padding-right: 3%;
}

/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
.overflowEllipsis___20U_0 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Total Palette */
/*  --- DEPRECATED; DON'T USE --- */
/*  --- DEPRECATED; DON'T USE --- */
/* Background color and text color for `<body> */
/* Global textual link color */
/* Global textual secondary link color */
/* Spacing */
/* Typography */
/* Font sizes */
/* Letter spacing */
/* Font weight */
/* Headings */
/* Shadows */
/* Backgrounds colors */
/* Buttons */
/* Breakpoints */
/* V2 breakpoints */
/*  --- DEPRECATED; DON'T USE --- */
/*  --- DEPRECATED; DON'T USE --- */
/** @deprecated - migrate to tablet-v2 */
/** @deprecated - use not-tablet-v2 */
/** @deprecated use .desktop-v2 */
/** @deprecated use .not-desktop-v2 */
/** @deprecated - use V2 media queries */
/** @deprecated - use V2 media queries */
/** @deprecated - use desktop-lg-v2 */
/** @deprecated - use .not-desktop-lg-v2 */
/**
 * overlap with desktop-lg bounds because not always applicable 
 *
 * @deprecated - use .desktop-xl-v2 
 */
/** @deprecated - use .not-desktop-xl-v2 */
/* default outline */
.outline-browser-default___-2_Pn {
  outline: 5px auto -webkit-focus-ring-color;
}
.viewMore___2zKjm {
  position: relative;
  background-color: #ffffff;
}
.viewMore___2zKjm .overflow___TJSwi {
  background-color: inherit;
}
.viewMore___2zKjm .more___1dDVw {
  position: absolute;
  display: inline-block;
  right: 0;
  bottom: 0;
  background-color: inherit;
  white-space: nowrap;
}
.viewMore___2zKjm .more___1dDVw .fade___2wgJJ {
  position: absolute;
  left: -3em;
  width: 3em;
  height: 100%;
  background-image: linear-gradient(to right, transparent, #ffffff 80%);
}
.viewMore___2zKjm .more___1dDVw .button___1xojN {
  display: inline-block;
  text-transform: none;
}
.viewMore___2zKjm .less___10Fof {
  display: none;
  background-color: inherit;
}
.viewMore___2zKjm .less___10Fof .button___1xojN {
  display: inline-block;
  text-transform: none;
  white-space: nowrap;
}
.viewMore___2zKjm.open___2jcTX {
  -webkit-line-clamp: unset !important;
}
.viewMore___2zKjm.open___2jcTX .more___1dDVw {
  display: none;
}
.viewMore___2zKjm.open___2jcTX .less___10Fof {
  display: inline;
}

.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zola-ui.tab-component .tab-overlay {
  position: relative;
}
.zola-ui.tab-component .fade-edges {
  height: 52px;
  position: absolute;
  width: 100%;
  left: 0;
}
.zola-ui.tab-component .fade-edges.narrow {
  right: 100px;
  width: auto;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .fade-edges.narrow {
    width: 100%;
  }
}
.zola-ui.tab-component .fade-edges.narrow::after {
  right: 0;
  position: absolute;
  content: '';
  z-index: 20;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 767px) {
  .zola-ui.tab-component .fade-edges.narrow::after {
    bottom: -5px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .zola-ui.tab-component .fade-edges::before {
    position: absolute;
    content: '';
    z-index: 20;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    left: 0;
    bottom: -5px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .zola-ui.tab-component .fade-edges::after {
    position: absolute;
    content: '';
    z-index: 20;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    right: 0;
    bottom: -5px;
    height: auto;
  }
}
.zola-ui.tab-component .tab-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100px;
  right: 0;
  height: 57px;
  top: 0;
  z-index: 20;
  background-color: #ffffff;
  padding-right: 16px;
  border-bottom: 1px solid #b7b7b7;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .tab-arrows {
    display: none;
  }
}
.zola-ui.tab-component .tab-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--text-primary, #0e0e0e);
  width: 32px;
  height: 32px;
  margin-left: 4px;
  margin-right: 4px;
  outline: none;
  border-radius: 100%;
  border: 1px solid #0e0e0e;
  font-size: 20px;
}
.zola-ui.tab-component .tab-arrow:disabled {
  color: #b7b7b7;
  border-color: #b7b7b7;
}
.zola-ui.tab-component .tab-arrow:disabled:hover {
  cursor: default;
}
.zola-ui.tab-component .tab-arrow .arrow-icon {
  width: 16px !important;
  height: 16px !important;
}
.zola-ui.tab-component .tabs-list-container {
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 20px;
}
.zola-ui.tab-component .tabs-list {
  position: relative;
  display: flex;
  overflow-x: auto;
  list-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.zola-ui.tab-component .tabs-list::-webkit-scrollbar {
  display: none;
}
.zola-ui.tab-component .tabs-list:focus {
  outline: none;
}
.zola-ui.tab-component .tabs-list.show-focus-outline:focus {
  outline: 2px solid #0075ae;
}
@media (min-width: 768px) {
  .zola-ui.tab-component .tabs-list.with-arrows {
    margin-right: 100px;
  }
  .zola-ui.tab-component .tabs-list.with-arrows .tab:last-child {
    padding-right: 20px;
  }
  .zola-ui.tab-component .tabs-list.with-arrows .current-tab:last-child::before {
    right: 20px;
  }
}
.zola-ui.tab-component .tab {
  color: var(--text-tertiary, #757575);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 16px;
  flex-shrink: 0;
  list-style: none;
}
.zola-ui.tab-component .tab:hover {
  color: var(--text-primary, #0e0e0e);
  cursor: pointer;
}
.zola-ui.tab-component .tab:first-child {
  padding-left: 0;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .tab:first-child {
    padding-left: 20px;
  }
}
.zola-ui.tab-component .tab:last-child {
  padding-right: 0;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .tab:last-child {
    padding-right: 20px;
  }
}
.zola-ui.tab-component .current-tab {
  color: var(--text-primary, #0e0e0e);
  position: relative;
}
.zola-ui.tab-component .current-tab::before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 4px;
  background-color: var(--text-primary, #0e0e0e);
}
.zola-ui.tab-component .current-tab:first-child::before {
  left: 0;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .current-tab:first-child::before {
    left: 20px;
  }
}
.zola-ui.tab-component .current-tab:last-child::before {
  right: 0;
}
@media (max-width: 767px) {
  .zola-ui.tab-component .current-tab:last-child::before {
    right: 20px;
  }
}
@media (max-width: 767px) {
  .zola-ui.tab-component .panel-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
/* stylelint-disable no-descending-specificity */
.zola-ui.small-tab-component .fade-edges {
  height: 42px;
}
.zola-ui.small-tab-component .tab {
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
}
.zola-ui.small-tab-component .current-tab::before {
  left: 12px;
  right: 12px;
}
.zola-ui.small-tab-component .tab-arrows {
  height: 47px;
}
.zola-ui.wide-tab-component .tab {
  padding-left: 30px;
  padding-right: 30px;
}
.zola-ui.wide-tab-component .tab:first-child {
  padding-left: 30px;
}
@media (max-width: 767px) {
  .zola-ui.wide-tab-component .tab:first-child {
    padding-left: 30px;
  }
}
.zola-ui.wide-tab-component .tab:last-child {
  padding-right: 30px;
}
@media (max-width: 767px) {
  .zola-ui.wide-tab-component .tab:last-child {
    padding-right: 30px;
  }
}
.zola-ui.wide-tab-component .current-tab::before {
  left: 0;
  right: 0;
}
.zola-ui.wide-tab-component .current-tab:first-child::before {
  left: 0;
}
@media (max-width: 767px) {
  .zola-ui.wide-tab-component .current-tab:first-child::before {
    left: 0;
  }
}
.zola-ui.wide-tab-component .current-tab:last-child::before {
  right: 0;
}
@media (max-width: 767px) {
  .zola-ui.wide-tab-component .current-tab:last-child::before {
    right: 0;
  }
}

.zola-ui.tag {
  color: #ffffff;
  display: inline-block;
  border-radius: 50px;
  line-height: 1.42857143;
}
.zola-ui.tag .zola-ui-icon {
  margin-right: 5px;
}
.zola-ui.tag--lg {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 9px;
}
.zola-ui.tag--sm {
  font-size: 9px;
  padding: 3px 8px 1px 8px;
  letter-spacing: 1.2px;
}
.zola-ui.tag--primary {
  background-color: #d7f5d9;
  color: #005759;
}
.zola-ui.tag--light-navy {
  background-color: #336081;
}
.zola-ui.tag--alternate {
  background-color: #f3f3f3;
  color: #0e0e0e;
}
.zola-ui.tag--secondary {
  background-color: #ffffff;
  border: 1px solid #005759;
  color: #005759;
}
.zola-ui.tag--red {
  background-color: #ffebf8;
  color: #c7305b;
}
.zola-ui.tag--orange {
  background-color: #ff9a00;
  color: #ffffff;
}
.zola-ui.tag--filter,
.zola-ui.tag--white-filter.tag {
  padding: 1px 1px 1px 10px;
  line-height: 16px;
}
.zola-ui.tag--filter .zola-ui-icon.zola-ui-icon-close,
.zola-ui.tag--white-filter.tag .zola-ui-icon.zola-ui-icon-close {
  width: 15px;
  float: right;
  border-radius: 50%;
  margin: 1px 1px 0 11px;
  line-height: 14px;
}
.zola-ui.tag--filter .zola-ui-icon.zola-ui-icon-close::before,
.zola-ui.tag--white-filter.tag .zola-ui-icon.zola-ui-icon-close::before {
  vertical-align: middle;
  margin-left: 1.5px;
}
.zola-ui.tag--filter {
  background-color: #d7f5d9;
  color: #005759;
}
.zola-ui.tag--white-filter.tag {
  background-color: #ffffff;
  border: 1px solid #757575;
  color: #757575;
}
.zola-ui.tag--white-filter.tag .zola-ui-icon.zola-ui-icon-close {
  background-color: #757575;
  color: #ffffff;
}
.zola-ui.tag--emoji {
  background-color: #ffffff;
  padding: 4px 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary, #0e0e0e);
  line-height: 1.45;
  display: inline-flex;
  align-items: center;
}
.zola-ui.tag--emoji .symbol {
  font-size: 16px;
  line-height: 1.3;
  margin-right: 6px;
}
.zola-ui.tag:hover.hover-state {
  cursor: pointer;
}

.container___17fbv {
  position: relative;
}
.inlineContainer___3F8Ws {
  display: inline-block;
  vertical-align: middle;
}
.label___2jBh2 {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary, #0e0e0e);
  line-height: 32px;
}
@media (min-width: 768px) {
  .label___2jBh2 {
    line-height: 24px;
  }
}
.labelLeft___3-Od0 {
  margin-right: 8px;
}
.labelRight___2zk4a {
  margin-left: 8px;
}
.labelBold___3xG0n {
  color: var(--text-primary, #0e0e0e);
  font-weight: 600;
}
.labelInline___2DobL {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
@media (min-width: 768px) {
  .labelInline___2DobL {
    height: 24px;
  }
}
.helperText___2OWck {
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2px;
  width: 100%;
  color: #ffffff;
  background-color: #c7305b;
}
.toggleWrapper___10gC7 .react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-track {
  padding: 0;
  border-radius: 120px;
  background-color: var(--background-light, #ffffff);
  border: var(--border-element-hover, 1px solid #b7b7b7);
  transition: all 0.2s ease;
  width: 60px;
  height: 32px;
}
@media (min-width: 768px) {
  .toggleWrapper___10gC7 .react-toggle .react-toggle-track {
    width: 46px;
    height: 24px;
  }
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--checked .react-toggle-track {
  border-color: var(--interactive-primary, #15845d);
  background-color: var(--interactive-primary-hover-background, #d7f5d9);
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--disabled {
  cursor: not-allowed;
  /* stylelint-disable-next-line no-descending-specificity */
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--disabled .react-toggle-track {
  background-color: var(--background-light, #ffffff);
  border-color: var(--border-element-color, #dbdbdb);
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--disabled .react-toggle-thumb {
  background-color: var(--border-element-color, #dbdbdb) !important;
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--disabled:active {
  box-shadow: none;
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-track-check,
.toggleWrapper___10gC7 .react-toggle .react-toggle-track-x {
  position: absolute;
  width: auto;
  top: 1px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  transition: opacity 0.25s ease;
  height: 32px;
}
@media (min-width: 768px) {
  .toggleWrapper___10gC7 .react-toggle .react-toggle-track-check,
  .toggleWrapper___10gC7 .react-toggle .react-toggle-track-x {
    height: 24px;
  }
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-track-check {
  left: 6px;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-track-x {
  right: 3px;
  opacity: 1;
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}
.toggleWrapper___10gC7 .react-toggle .react-toggle-thumb {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--border-element-hover-color, #b7b7b7);
  transition: all 0.25s ease;
}
@media (min-width: 768px) {
  .toggleWrapper___10gC7 .react-toggle .react-toggle-thumb {
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
  }
}
.toggleWrapper___10gC7 .react-toggle.react-toggle--checked .react-toggle-thumb {
  left: 30px;
  background-color: var(--interactive-primary, #15845d);
}
@media (min-width: 768px) {
  .toggleWrapper___10gC7 .react-toggle.react-toggle--checked .react-toggle-thumb {
    left: 25px;
  }
}
.destructive___2CEgz .react-toggle .react-toggle-track {
  border-color: #c7305b;
}
.destructive___2CEgz .react-toggle.react-toggle--checked .react-toggle-thumb {
  background-color: #c7305b;
}
.icon___QnRvI {
  font-size: 16px;
  color: #ffffff;
  line-height: 32px;
}
@media (min-width: 768px) {
  .icon___QnRvI {
    line-height: 24px;
    font-size: 12px;
  }
}
.icon___QnRvI i {
  color: #b7b7b7;
  font-size: 16px;
  line-height: 32px;
}
@media (min-width: 768px) {
  .icon___QnRvI i {
    font-size: 12px;
    line-height: 24px;
  }
}
.icon___QnRvI i.zola-ui-icon-close {
  font-size: 20px;
}
@media (min-width: 768px) {
  .icon___QnRvI i.zola-ui-icon-close {
    font-size: 16px;
  }
}
.icon___QnRvI i.zola-ui-icon-checkmark {
  color: #15845d;
}

