@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  .not-mobile({
    max-width: 1200px;
    justify-content: space-between;
    padding: 26px 20px;
    background: @color-white;
    border-radius: 4px;
    box-shadow: @default-box-shadow;
  });
  .tablet({
    margin-bottom: 20px;
  });
  .mobile({
    overflow-x: scroll;
    width: 750px;
    white-space: normal;
    padding: 2px;
  });
  display: flex;
}

.cardContainer {
  width: 20%;

  .mobile({
    background: @color-white;
    box-shadow: @default-box-shadow;
    text-align: center;
    border-radius: 4px;
    width: 140px;
    height: 100px;
    padding-top: 12px;
    margin-right: 12px;
  });
}

.divider {
  border-left: 1px solid #eee;
  padding-left: 24px;
  padding-right: 24px;
  .tablet({
    padding-left: 12px;
    padding-right: 12px;
  });
  .mobile({
    padding-left: 12px;
    padding-right: 12px;
  });
}
