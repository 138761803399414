@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  display: flex;
  overflow-x: auto;
  width: ~'calc(100% + 20px)';
  .conceal-scrollbars();
  @media (min-width: 1024px) {
    max-width: 960px;
    justify-content: center;
    flex-wrap: wrap;
    gap: @spacing-xs 0;
    margin: auto;
  }
  &::after {
    width: 20px;
    content: ' ';
    height: 52px;
    background: linear-gradient(270deg, @color-white 0%, rgba(252, 252, 252, 0) 100%);
    position: absolute;
    display: block;
    right: 0;
  }
}

.chip {
  flex-shrink: 0;
  border-radius: 16px !important;
  border-color: @color-black-030 !important;
  font-weight: @font-weight-regular !important;
  & + & {
    margin-left: @spacing-xs;
  }
}

.activeChip {
  border-color: @color-black-100 !important; // ;__; zola-ui
  background-color: @color-black-005 !important; // ;__; zola-ui
}

.spacer {
  padding-right: 20px;
}
