@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.lightGrayBg {
  background-color: @color-privilege;
}

.paleYellow {
  background-color: @color-pale-yellow;
}
