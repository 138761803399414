@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

// DO NOT MODIFY ANY LIBRARY CODE BELOW THIS LINE UNLESS YOU HAVE A GOOD REASON
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: unset;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: @zIndex-content-middle;
  border: 0;
  //background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}
