@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.marketingModule {
  border-bottom: 1px solid @color-black-030;
  padding: @spacing-24;
  .not-mobile({
    padding: @spacing-lg;
  });
  picture {
    display: flex;
    img {
      border-radius: 16px;
      width: 100%;
      max-width: 1300px;
      margin: auto;
    }
  }
}
