@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.section-title {
  // TODO: replace with 'landingPageShared.module.less'
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  font-size: @zui-fontsize-larger;
  color: @color-navy;
  line-height: @headings-line-height;
  .tablet({
      .text-size--compact(@zui-fontsize-larger) !important;
    });
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
  .not-desktop({
      max-width: 455px;
    });
  .not-mobile({
      .text-size--compact(@zui-fontsize-semi-huge);
    });
}

.cashContainer {
  .desktop({
    padding-left: 74px;
  });
}

// hero
// (double classnames to override '.hero' w/specificity)
.cashHero.pngHero {
  // dynamic size for bg-img:
  background-size: 54%;
  background-position: top right;
  .desktop({
    // dynamic size for content
    :global(.hero__DesktopContents) {
      width: 44%;
    }
  });
  @media (min-width: 1200px) {
    // set sizes (bg-img & content) for largest page width
    background-size: 665px;
    background-position-x: ~'calc(50vw - 64px)';
    :global(.hero__DesktopContents) {
      width: 500px;
    }
  }
}
.cashHero {
  background-color: @color-secondary-background-gray;
  background-repeat: no-repeat;
  position: relative;
  .tablet({
    padding-bottom: 80px;
    height: auto;
  });
  .mobile({
    padding-bottom: 60px;
  });
  & > :global(.hero__notDesktop) {
    position: absolute;
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: center 205px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  @media (max-width: @screen-sm) {
    height: auto;
  }
  .not-desktop({
    height: 550px;
    div:nth-of-type(2) {
      z-index: 10;
    }
    :global(.hero__body) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 420px;
    }
    :global(.hero__DesktopContents > :first-child) {
      // enforce line break for smaller widths
      max-width: 10em;
      margin: 0 auto;
    }
  });
}

.cashHeroDek {
  margin-top: 20px;
  .not-desktop({
    margin: 20px auto 0;
    max-width: none;
  });
}

// Fund Anything - carousel
.fund-section {
  background-color: @color-privilege;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .cta-container {
    background-color: @color-privilege;
    padding-top: 30px;
    text-align: center;
  }
}
.fund-title {
  margin-bottom: 14px;
}

// How it works
.how-section {
  background-color: @color-privilege;
  .tablet({
    :global(.ctaSection--tablet > .ctaSection__title) {
      margin: 0 auto 20px;
    }
  });
}

// "get more" text section
.text-section {
  background-color: @color-privilege;
  margin: 0 auto;
  padding: 0 20px 20px;
  text-align: center;
  width: 100%;

  .not-desktop({
    padding: 40px 20px 0;
  });
  & .body {
    color: @color-black;
    font-size: @zui-fontsize-regular;
    font-weight: @proxima-regular;
  }
}

// image sections
.section-image-1,
.section-image-2,
.section-image-3 {
  height: 380px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  .tablet({
    height: 340px;
    width: calc(100vw - 120px);
  });
  .mobile({
    height: 300px;
    width: calc(100vw - 40px);
    margin-bottom: 20px;
  });
  max-width: 472px;
  .not-desktop({
    max-width: 412px;
  });
}
.cta-container {
  background-color: @color-privilege;
  text-align: center;
  padding: 40px 0;
}

.cashHero {
  & > :global(.hero__notDesktop) {
    background-size: 275px;
    background-position: center 225px;
  }
}
