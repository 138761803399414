@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.testimonialsHeroContainer {
  height: 100% !important;
  max-width: unset !important;
  background-color: @color-background-cloud;
  &::before,
  &::after {
    display: none;
  }
  // need these to overwrite zola-ui ;__;
  .mobile({
    padding: 40px 20px 52px !important;
  });
  .tablet({
    padding: 60px 20px 80px;
  });
  :global {
    .testimonials-hero__content {
      max-width: 1300px;
      position: relative;
    }

    .testimonial-card {
      margin: 0 auto;
      width: 95%;
      height: 100%;
      padding-left: 17px;
      padding-right: 15px;

      @media (max-width: 1170px) {
        width: 95%;
      }
    }

    .testimonial-card__body {
      padding-top: 0;
      padding-left: 15px;
      padding-right: 15px;
    }

    .testimonial-card__body-header {
      margin-top: 10px;
    }
    .testimonial-card__body-description {
      font-weight: @font-weight-regular;
      .text-size--compact(@zui-fontsize-small);
    }

    .testimonial-card__title--row {
      padding-left: 10px;
    }

    @import '../../../styles/reactMultiCarousel.less';

    .react-multiple-carousel__arrow--left {
      left: -12px;
    }

    .react-multiple-carousel__arrow--right {
      right: -12px;
    }

    .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 0;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .react-multi-carousel-dot button {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 1;
      padding: 5px 5px 5px 5px;
      box-shadow: none;
      transition: background 0.5s;
      border-width: 2px;
      border-style: solid;
      border-color: grey;
      padding: 0;
      margin: 0;
      margin-right: 6px;
      outline: 0;
      cursor: pointer;
    }

    .react-multi-carousel-dot button:hover:active {
      background: #080808;
    }

    .react-multi-carousel-dot--active button {
      background: #080808;
    }
  }
}
