@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '../../../styles/expertAdviceShared.module.less';

.container {
  padding-top: @spacing-lg;
}
.sponsoredContainer {
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}
.sponsoredBodyContainer {
  margin-top: 24px;
  background-color: @color-privilege;
  padding-bottom: 60px;
  margin-bottom: 60px;
}
.sponsoredBodyMarkdown {
  * {
    font-weight: @font-weight-semi-bold;
  }
}
.sponsoredAboutContainer {
  box-shadow: @default-box-shadow;
  padding: 40px 40px 60px 40px;
  margin-right: -20px;
  margin-left: -20px;
}
