@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px 40px 80px;
  .mobile({
    padding: 40px 20px 52px;
  });
  .tablet({
    text-align: center;
  });
}

.mobileImageContainer {
  display: flex;
  flex-wrap: wrap;
}

.mobileItem {
  flex-basis: 50%;
}

.mobileATag {
  display: block;
}

.cta {
  display: block;
  .mobile({
    margin-top: 12px;
  });
  .tablet({
    margin-top: 40px;
  });
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  font-size: @zui-fontsize-semi-huge;
  color: @color-navy;
  .mobile({
    text-align: center;
    height: 100%;
  });
  .not-desktop({
    font-size: @zui-fontsize-larger;
    margin: 0 auto;
  });
}

.arrow {
  position: absolute;
  top: 50%;
}

.image {
  width: 100%;
  .not-mobile({
    padding: 0 20px;
  });
}

.container {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.carousel {
  overflow: hidden;
  min-height: 280px;
  display: flex;
  .mobile({
    flex-wrap: wrap;
  });

  :global {
    @import '../../../styles/reactMultiCarousel.less';

    .react-multiple-carousel__arrow--left {
      left: -22px;
    }

    .react-multiple-carousel__arrow--right {
      right: -22px;
    }
  }
}

.onlyMobile {
  .not-mobile({
    display: none;
  });
}

.notMobile {
  .mobile({
      display: none;
  });
}
