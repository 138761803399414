@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.title {
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  color: @color-navy;
  margin: 0;
  font-size: @zui-fontsize-larger;
  .tablet({
      font-size: @zui-fontsize-semi-huge;
    });
  .desktop({
      font-size: @zui-fontsize-huge;
    });
}
