@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0 15% 0;
  padding-top: 0;
  padding-bottom: 40px;
  .tablet({
    margin: 0 100px;
    padding-top: 60px;
  });
  .mobile({
    margin: 0 20px;
    padding-top: 32px;
  });
}

.count {
  margin-bottom: 40px;
  .mobile({
    margin-bottom: 32px;
    text-align: left;
    width: 100%;
  });
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 514px;
  width: 100%;
}
