@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.mobileAppBadge {
  + .mobileAppBadge {
    margin-left: 10px;
  }
}
.mobileAppBadgeImg {
  height: 48px;
  width: auto;
}
