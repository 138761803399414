@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.hero {
  background-size: cover;
  background-position: center;
  width: 100%;
  .desktop({
    min-height: 500px;
  });
  .not-desktop({
    background-image: none !important;
  });
}

.blockImage {
  height: 300px;
  background-size: cover;
  background-position: center top;
  .not-mobile-xs({
      height: 390px;
    });
  .tablet({
      background-position-x: right;
    });
  .desktop({
      display: none;
    });
}

.contents {
  padding: 40px 20px 52px;
  margin: 0;
  height: 100%;
  max-height: 100%;
  text-align: center;
  .desktop({
    padding: 75px 60px 0;
    text-align: left;
    width: 100%;
    max-width: @screen-lg;
    margin: 0 auto;
  });
}

.title {
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  color: @color-navy;
  margin: 0;
  font-size: @zui-fontsize-larger;
  .tablet({
    font-size: @zui-fontsize-semi-huge;
  });
  .desktop({
    font-size: @zui-fontsize-huge;
  });
}

.caption {
  font-weight: @proxima-regular;
  font-family: @font-family-base;
  .text-size--compact(@zui-fontsize-regular);
}

.bulletedList {
  display: flex;
  flex-direction: column;
  .not-desktop({
    align-items: center;
    text-align: left;
  });

  :global {
    ul.bulleted-list {
      margin-top: 15px;
      .not-mobile({
        margin-top: 20px;
        margin-bottom: 0;
      });
      li {
        margin-bottom: 4px;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .not-mobile({
    flex-direction: row;
    margin-top: 30px;
  });
  .desktop({
    justify-content: flex-start;
  });
  :global {
    .v2-button {
      .mobile({
          width: 100%;
        });
      max-width: 335px;
      & + .v2-button {
        margin-top: 1rem;

        .not-mobile({
            margin-top: 0;
            margin-left: 1rem;
          });
      }
    }
  }
}

.borderBottom {
  .not-desktop({
    border-bottom: solid 1px @color-lighter-blue;
  });
}
