@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  box-shadow: @default-box-shadow;
  background-color: @color-white;
  padding: 32px;

  .mobile({
    padding: 20px;
  });
  .desktop({
    background: right 0 / contain no-repeat url('../../../../public/static-preauth/images/expert-advice/confetti_wave_bg_light.png');
  });

  .not-desktop({
    margin-bottom: 40px;
  });
}

.maxWidthContainer {
  .desktop({
    max-width: 70%;
  });
}

.markdownStyleOverride {
  :global {
    p,
    ul,
    ol {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
