@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

// slide up animation for search results
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  // vertically center result
  display: flex;
  border-top: 1px solid @color-alto;
  padding: 24px 0px;
  height: 96px;
  .mobile({
    padding-top: 15px;
    height: 80px;
    height: fit-content;
  });
  .not-mobile({
    padding-top: 30px;
  });

  // adding small animation for better UX
  animation: slide-up 0.4s ease;

  .name,
  .date,
  .nameLink,
  > p {
    width: 100%;
    text-align: left;

    @media (min-width: 480px) {
      text-align: center;
    }

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.container:last-child {
  border-bottom: 1px solid @color-alto;
}

.result {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 480px) {
    text-align: center;
  }
  .tablet({
    justify-content: space-between;
  });
  .mobile({
    flex-direction: column;
  });
}

.name {
  width: 45%;
  font-weight: @font-weight-semi-bold;
  line-height: @zui-fontsize-large;
  color: @color-black;
  .tablet({
    display: none;
  });
  .mobile({
    width: 100%;
    font-size: @zui-fontsize-regular;
  });
  a {
    text-decoration: none;
  }
}
.nameLink {
  .not-tablet({
    display: none;
  });
}

.date {
  width: 30%;
  line-height: 24px;
  color: @color-emperor;
  .mobile({
    width: 100%;
  });
}

.link {
  width: 17%;
  .not-desktop({
    display: none;
  });
}
