@import (reference) '~@zola/zola-ui/src/styles/common/_variables';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins';

.nav {
  position: relative;
  display: none;
  z-index: unset !important;
  min-width: unset !important;
}

.not-desktop({
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    img {
      height: 16px;
    }
  }

});

.logoRebrand {
  width: 151px;
  height: 20px;
  margin: 0 auto 0 24px;
}
