@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.card {
  display: flex;
  .mobile({
    flex-direction: column;
  });
}

.card:not(.completed):hover {
  text-transform: capitalize;
  .icon {
    box-shadow: @default-box-shadow;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iconWrapper {
  .not-mobile({
    margin-right: 12px;
  });
  img {
    border-radius: 50%;
    .mobile({width: 28px});
  }
}

.ctaText {
  text-decoration-line: line-through;
  color: @color-boulder;
}
