@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.secondaryImage {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .desktop({
    position: absolute;
    left: -20%;
    top: -150px;
    height: 675px;
    width: 135%;
  });
  .tablet({
    height: 360px;
    width: calc(100vw - 120px);
  });
  .mobile({
    max-height: 300px;
    width: calc(100vw - 40px);
  });
}
.imgContainer {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.secondaryContentsOverride {
  margin-top: 20px;
  .not-desktop({
      margin-bottom: 20px;
      margin-right: auto;
      margin-left: auto;
  });
}

@title-max-width: 450px;
.subtitle {
  margin-top: 82px;
  .tablet({
    margin-top: 50px;
  });
  .mobile({
    margin-top: 0;
  });
  .not-desktop({
    text-align: center;
    max-width: @title-max-width;
  });
}

.title {
  .desktop({
    margin-top: 45px; // normal hero padding: 75px 60px 0;
  });
  max-width: @title-max-width;
  margin: auto;
}

.btnSpacing {
  .desktop({
    margin-top: 32px;
  });
  :global(a) {
    .mobile({
      width: 100%;
    });
  }
}

// img stuff
@tablet-img-max-width: 360px;
.shadow {
  .desktop({
    display: none;
  });
  background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/hero/oval.svg');
  background-position: bottom;
  background-size: cover;
  width: 100%;
  position: absolute;
  z-index: 0;

  // proportional height =
  // (<screen size width> - <hero total side space>) * hero image <original height> / <original width>.
  @hero-image-proportional-height: '(100vw - 40px) * 364 / 662';
  @relative-view-width: 100vw;

  height: ~'calc(@{relative-view-width} + (@{hero-image-proportional-height}))';
  // This fixes the bottom of the image.
  top: ~'calc(-@{relative-view-width} + 409px)';

  // Sets the max height (based on the max-width of the hero image)
  // <image max-width> + 40 (total side space).
  @tablet-max-height-breakpoint: @tablet-img-max-width + 40px;

  @media (min-width: (@tablet-max-height-breakpoint)) {
    @hero-image-proportional-height: '(@{tablet-img-max-width} - 40px) * 364 / 662';
    @relative-view-width: @tablet-max-height-breakpoint;

    height: ~'calc(@{relative-view-width} + (@{hero-image-proportional-height}))';
    top: ~'calc(-@{relative-view-width} + 317px)';
  }

  // prettier-ignore
  .tablet({
    @hero-image-proportional-height: '(768px - 100px) * 768 / 930';
    @relative-view-width: 768px;

    height: ~'calc(@{relative-view-width} + (@{hero-image-proportional-height}))';
    top: ~'calc(-@{relative-view-width} + 86px)';
  });
}

.infoTilesContainer {
  width: 100%;
  max-width: 1280px;
  padding: 0px 20px 52px 20px;
  .desktop({
    padding: 0px 30px 60px 30px;
  });
  margin-top: -30px;
  .not-desktop({
    margin-top: -52px;
  });
}
.outerColor {
  background-color: @color-privilege;
}
