@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 272px;
  padding: 0 10px;

  .mobile({
    height: 265px;
    });
}

.title {
  .mobile({
        margin-bottom: 13px;
      });

  .not-mobile({
        margin-bottom: 11px;
      });
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  color: @color-navy;
  margin-top: 0;

  .mobile({
        .text-size--compact(@font-size-capital-letter);
      });

  .not-mobile({
        .text-size--compact(@font-size-h3);
      });
}

.dek {
  font-family: @font-family-base;
  color: @color-emperor;
  font-weight: @proxima-regular;
  margin: 0;
  padding: 0;
  .text-size--compact(@zui-fontsize-regular);

  .mobile({
      max-width: ~'calc(100% - 52.5px * 2)';
    });

  .not-mobile({
      max-width: 459px;
    });
}

.footer {
  .text-size--compact(@font-size-base);

  .mobile({
      margin-top: 13px;
    });

  .not-mobile({
      margin-top: 11px;
    });
}
