@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.articlePageBody {
  .articlePageBodyInner {
    max-width: 680px;
    margin: @spacing-lg auto @spacing-xl;

    .tablet({
      margin: 0 auto @spacing-lg;
    });

    .mobile({
      margin: @spacing-xs @spacing-24 @spacing-lg;
    });
  }

  .title {
    font-weight: @font-weight-medium;
    
    .not-desktop({
      font-size: @zui-fontsize-larger;
      line-height: 40px;
    });
  }

  .excerpt {
    margin-top: @spacing-16;
  }

  .author {
    font-weight: @font-weight-semi-bold;
    margin-top: @spacing-16;
  }

  .updatedAt {
    margin-top: @spacing-xs;
  }

  .heroImageContainer {
    display: flex;
    flex-direction: column;
    margin-top: @spacing-lg;

    img {
      width: 100%;
      border-radius: @spacing-16;
    }
  }

  .heroCredit {
    font-size: @zui-fontsize-extra-small;
    text-align: right;
  }

  .tldr {
    margin-top: @spacing-lg;
    padding: @spacing-md;
    background-color: @color-black-005;
    border-radius: @spacing-16;
    font-size: @zui-fontsize-small;

    .desktop({
      padding: @spacing-lg;
    });

    h2 {
      font-size: @zui-fontsize-regular;
    }

    a,
    li {
      font-size: @zui-fontsize-small;
    }
  }
}

.featuredArticles {
  border-top: 1px solid @color-black-030;
}

.seoRelatedLinks {
  border-bottom: 1px solid @color-black-030;

  > div {
    border-top: none;
    margin: 0;
  }
}
