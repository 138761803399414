@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.hideDesktop {
  .desktop({
        display: none;
    });
}

.rose {
  color: @color-hard-pink;
}

.isPostAuthNav3Enabled {
  .desktop({
    padding-left: 74px;
  });
}
