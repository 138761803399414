@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.contentContainer {
  padding: 0 @spacing-lg;
  max-width: @screen-xl;
  margin-left: auto;
  margin-right: auto;
  .mobile({
    padding: @spacing-16 @spacing-24;
  });
}
