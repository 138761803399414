@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.carousel {
  position: relative;
}

@arrow-button-height: 44px;
.arrow {
  position: absolute;
  top: calc(50% - (@arrow-button-height / 2));
}

.rightArrow {
  right: 0;
}

.leftArrow {
  left: 0;
}

.infoTile {
  :global {
    .porthole-img__container {
      background-color: @color-white;
      border-radius: 50%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      .mobile({
        margin-bottom: 10px;
      });
    }
    .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
      width: 75%;
      border-radius: 0 !important;
    }
  }
}

.infoTileColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  > :first-child {
    margin-bottom: 32px;
  }
}
