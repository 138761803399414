@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sponsorName {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.button {
  margin-top: 32px;
}
