@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/animations/_mixins.less';

.weddingPhoto {
  cursor: pointer;
  :global {
    .uploadcare--widget {
      display: none;
    }
  }
  .imageContainer {
    position: relative;
  }
  .profileCompletedLogo {
    width: 35px;
    position: absolute;
    right: -10px;
    top: -25px;
  }
  .weddingPhotoImg {
    width: 88px;
    border-radius: 50%;
    .mobile({
      width: 80px;
    });
  }
  .weddingPhotoImg.default {
    border: 1px @color-jewel-variant-40 dashed;
    &:hover {
      border-color: @color-blue;
    }
  }

  .scaleBase();
  &:hover {
    .scale(1.2);
  }
}
