@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  padding: 24px @spacing-md @spacing-md;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .tablet({
    padding-left: 31px;
    padding-right: 31px;
  });
  .desktop({
    padding-left: 38px;
    padding-right: 38px;
  });
  .desktop-lg({
    padding-left: 62px;
    padding-right: 62px;
    padding-bottom: 60px;
  });
  .desktop-xl({
    padding-left: 70px;
    padding-right: 70px;
  });

  &.isPostAuthNav3Enabled {
    // add 74px to all left padding
    .desktop({
      padding-left: 112px;
    });
    .desktop-lg({
      padding-left: 136px;
    });
    .desktop-xl({
      padding-left: 144px;
    });
  }
}
