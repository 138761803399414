@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@p-spacing: 16px;

.markdown {
  h2 {
    font-family: @font-family-sans-serif;
    font-weight: @font-weight-semi-bold;
    font-size: @zui-fontsize-large;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: @spacing-16;
    .desktop({
      margin-top: @spacing-lg;
    });
  }
  h3 {
    font-size: @zui-fontsize-regular;
    letter-spacing: 0px;
    line-height: 36px;
    font-weight: @proxima-semi-bold;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  h4 {
    font-size: 20px;
    letter-spacing: 0;
    font-weight: @proxima-semi-bold;
    line-height: 32px;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: @spacing-md;
    font-size: @zui-fontsize-regular;
    margin-bottom: @spacing-lg;
    line-height: 32px;
    p {
      font-size: @zui-fontsize-regular;
    }
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: @spacing-md;
    font-size: @zui-fontsize-regular;
    line-height: 32px;
    p {
      font-size: @zui-fontsize-regular;
    }
  }

  blockquote {
    margin-bottom: 40px;
    margin-top: 20px;
  }

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > p > a > img {
    max-width: 100%;
  }
  > p {
    margin-bottom: @spacing-lg;
    font-size: @zui-fontsize-regular;
    line-height: 32px;
  }

  a {
    font-weight: @proxima-semi-bold;
  }

  hr {
    margin: 20px 0;
    border-top: 1px solid @color-privilege;
  }
  // offset anchors
  :target::before {
    @h2-spacing: 56px;
    @desktop-nav-height: 136px;
    @mobile-nav-height: 60px;
    // offset by nav height and h2 vertical spacing
    @desktop-offset: calc(@desktop-nav-height + @h2-spacing);
    @mobile-offset: calc(@mobile-nav-height + @h2-spacing);

    content: '';
    display: block;
    visibility: hidden;
    height: @desktop-offset;
    margin-top: calc(-1 * @desktop-offset);

    .not-desktop({
      height: calc(@mobile-offset);
      margin-top: calc(-1 * @mobile-offset);
    });
  }
}

.photoCredit {
  em {
    font-style: normal;
  }
  font-size: 12px;
  line-height: 20px;
  text-align: right;
}
.pStyling {
  font-family: @font-family-base;
  font-size: @zui-fontsize-regular;
  line-height: 32px;
  font-weight: @proxima-regular;
}
.image {
  img {
    width: 100%;
    border-radius: @spacing-16;
  }
}

.topSpacing {
  margin-top: @spacing-lg;
  .desktop({
    margin-top: @spacing-lg;
  });
}
@margin-bottom-desktop: 60px;
@margin-bottom-mobile: 40px;
.bottomSpacing {
  margin-bottom: @spacing-lg;
  .desktop({
    margin-bottom: @spacing-lg;
  });
}
.negativeBottomSpacing {
  margin-top: calc(-1 * @margin-bottom-mobile);
  .desktop({
    margin-top: calc(-1 * @margin-bottom-desktop);
  });
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
