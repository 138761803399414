@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.infoTile {
  :global {
    .porthole-img__container {
      background-color: @color-privilege;
      border-radius: 50%;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
      width: 75%;
    }
  }
}

.tiles {
  :global {
    .info-tile {
      .mobile({
            align-items: center !important;
        });
    }
  }
}

.dek {
  margin: auto;
}
