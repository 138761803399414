@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@spacing-left: 54px;
@max-content-width: 1300px;
.container {
  background: @color-muted-gray;
}

.header {
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  .text-size--compact(@zui-fontsize-large);
  color: @color-black;
  margin-bottom: @spacing-md;
  .desktop({
    .text-size--compact(@zui-fontsize-larger);
    max-width: @max-content-width;
  });
}

.faqs {
  &::after {
    content: ' ';
    width: 100%;
    height: 2px;
    background: @color-alto;
    display: block;
    margin-top: @spacing-md;
  }
  h1,
  h2,
  h3 {
    .text-size--compact(@zui-fontsize-small);
    font-weight: @proxima-semi-bold;
    margin-bottom: @spacing-xs;
    color: @color-blue;
    border-top: 2px solid @color-alto;
    padding-top: @spacing-md;
    padding-left: @spacing-left;
  }

  p {
    .text-size--compact(@zui-fontsize-small);
    padding-left: @spacing-left;
    margin-bottom: @spacing-md;
    .desktop({
      max-width: 50%;
    });
  }

  a {
    font-weight: @proxima-semi-bold;
  }
}
