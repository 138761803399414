@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.profileSectionContainer {
  position: relative;
  padding-top: 36px;
  background-image: url('../../../../public/static-preauth/images/YourWedding/profileSection/background.svg');
  background-size: cover;
  padding-bottom: 40px;
  height: 100%; // needed to put in for now so not overlapping with modulecardsection
  .mobile({
    padding-bottom: 20px;
  });
}

.profileSectionContainer.completed {
  padding-bottom: 0;
}

.profileSection {
  position: relative;
  z-index: 2;
}

.profile {
  display: flex;
  justify-content: space-between;
  .weddingInfo {
    display: flex;
    flex-grow: 2;
  }

  .mobile({
    flex-direction: column;
    .weddingInfo {
      flex-direction: column;
    }
  });

  .inlineWeddingInfo {
    display: flex;
    width: 100%;
    margin-bottom: 22px;
  }
}

.tasks {
  margin-top: 5px;
  .mobile({
    overflow-x: scroll;
  });
}

.backgroundConfetti {
  position: absolute;
}

.backgroundConfetti.top {
  top: 15px;
  left: 0;
}

.backgroundConfetti.bottom {
  right: 0;
  bottom: 0;
  .mobile({
    display: none;
  });
}

// helper

.notMobile {
  .mobile({
    display: none;
  });
}

.mobile {
  .not-mobile({
    display: none;
  });
}
