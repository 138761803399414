@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.strikethrough {
  :global {
    .selector-label {
      text-decoration: line-through;
      color: @color-emperor !important;
    }
  }
}

.checklistItem {
  padding-bottom: 40px;
  &:last-child {
    padding-bottom: 0;
  }
  :global {
    .selector-icon-wrapper {
      margin-top: 6px;
    }
    .selector-label {
      font-weight: @proxima-semi-bold !important;
      .text-size--regular(@zui-fontsize-large) !important;
      color: @color-black;
    }
    .selector-dek {
      .text-size--compact(@zui-fontsize-regular) !important;
      color: @color-emperor !important;
    }
  }
}
