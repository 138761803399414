@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.secondary-image {
  height: 440px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  .tablet({
      height: 360px;
      width: calc(100vw - 120px);
    });
  .mobile({
      max-height: 240px;
      width: calc(100vw - 40px);
      margin-bottom: 20px;
    });
}
