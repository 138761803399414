@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '../../../styles/expertAdviceShared.module.less';

.rangeSections {
  width: 100%;
}
.checklistRange {
  padding-top: 0;
  padding-bottom: 52px;
  .not-mobile({
    max-width: @maxArticleWidth;
    margin: 0 auto;
  });
}

.image {
  img {
    margin: 20px 0 0;
    max-width: 100%;
  }
}

.rangeTitle {
  .text-size--regular(@zui-fontsize-larger);
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  color: @color-black;
  margin: 60px 0 20px;
}

.rangeDescription {
  p {
    .text-size--regular(@zui-fontsize-regular);
    color: @color-black !important;
  }
  margin-bottom: 20px;
  .not-mobile({
    margin-bottom: 40px;
  });
}

.photoCredit {
  width: 100%;
  text-align: right;
  .text-size--compact(@zui-fontsize-smaller);
  color: @color-boulder;
  font-weight: @proxima-regular;
}
