/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: 100% inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  min-width: 320px;
  overflow-x: auto;
}
body {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  color: var(--text-primary, #0e0e0e);
  background-color: var(--background-screen, #ffffff);
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
}
a {
  color: var(--text-primary, #0e0e0e);
  text-decoration: underline;
}
a:hover,
a:focus {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
[role='button'] {
  cursor: pointer;
}
.zui-svg-icon {
  width: auto;
}
.font-weight-light {
  font-weight: 100;
}
.font-weight-regular {
  font-weight: 400;
}
.font-weight-bold {
  font-weight: 700;
}
.font-family-serif {
  font-family: 'new-spirit', 'helvetica-bold', serif;
}
.font-family-script {
  font-family: 'Sakura Regular', 'Georgia', serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7 {
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.1;
  color: #21201f;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
.h7 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small,
.h7 .small {
  font-weight: 400;
  line-height: 1;
}
h1.font-family-script,
h2.font-family-script,
h3.font-family-script,
.h1.font-family-script,
.h2.font-family-script,
.h3.font-family-script,
h1 > .font-family-script,
h2 > .font-family-script,
h3 > .font-family-script,
.h1 > .font-family-script,
.h2 > .font-family-script,
.h3 > .font-family-script {
  font-size: 150%;
}
h1,
.h1 {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 32px;
  font-weight: 500;
}
h2,
.h2 {
  font-size: 30px;
  letter-spacing: 0;
  font-weight: 400;
}
h3,
.h3 {
  font-size: 24px;
  letter-spacing: 0.2px;
  font-weight: 400;
}
h4,
.h4 {
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 400;
}
h5,
.h5 {
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
h6,
.h6 {
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
p,
.p {
  margin: 0 0 11px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary, #0e0e0e);
}
p small,
.p small,
p .small,
.p .small {
  font-size: 12px;
}
@media (max-width: 992px) {
  p.responsive,
  .p.responsive {
    font-size: 18px;
  }
  p.responsive small,
  .p.responsive small,
  p.responsive .small,
  .p.responsive .small {
    font-size: 14px;
  }
}
.mobile-text p,
.mobile-text .p {
  font-size: 18px;
}
.mobile-text small,
.mobile-text .small {
  font-size: 14px;
}
:global {
  /**
 * Core Zola Fonts
 * must be in src root so build font-path matches src font-path
 */
  /* Custom Circular font for body - was added to the zola-web-assets bucket */
  /**
 * Zola Icons
 * must be in src root so build font-path matches src font-path
 */
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face { 
    font-family: 'zolaicons';
    src: url('../font/zolaicons.svg?30092283#zolaicons') format('svg');
  }
}
*/
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
@font-face {
  font-family: 'Sakura Regular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/SakuraRegular.woff');
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'zola-ui-icons';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.eot?46588171');
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.eot?46588171#iefix') format('embedded-opentype'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.woff2?46588171') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.woff?46588171') format('woff'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.ttf?46588171') format('truetype'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/zola-ui/zolaicons.svg?46588171#zolaicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
:global [class^='zola-ui-icon-']::before,
:global [class*=' zola-ui-icon-']::before {
  font-family: 'zola-ui-icons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
:global .zola-ui-icon-email::before {
  content: '\e800';
}
:global .zola-ui-icon-move-1::before {
  content: '\e801';
}
:global .zola-ui-icon-chevron-up::before {
  content: '\e802';
}
:global .zola-ui-icon-mobile::before {
  content: '\e803';
}
:global .zola-ui-icon-desktop::before {
  content: '\e804';
}
:global .zola-ui-icon-arrow-down::before {
  content: '\e805';
}
:global .zola-ui-icon-arrow-up::before {
  content: '\e806';
}
:global .zola-ui-icon-bank::before {
  content: '\e807';
}
:global .zola-ui-icon-call::before {
  content: '\e808';
}
:global .zola-ui-icon-chat::before {
  content: '\e809';
}
:global .zola-ui-icon-train::before {
  content: '\e80a';
}
:global .zola-ui-icon-shuttle::before {
  content: '\e80b';
}
:global .zola-ui-icon-warning-sign::before {
  content: '\e80c';
}
:global .zola-ui-icon-car-rental::before {
  content: '\e80d';
}
:global .zola-ui-icon-clock::before {
  content: '\e80e';
}
:global .zola-ui-icon-house-rental::before {
  content: '\e80f';
}
:global .zola-ui-icon-collection::before {
  content: '\e810';
}
:global .zola-ui-icon-credit-card::before {
  content: '\e811';
}
:global .zola-ui-icon-design::before {
  content: '\e812';
}
:global .zola-ui-icon-exchange::before {
  content: '\e813';
}
:global .zola-ui-icon-gift::before {
  content: '\e814';
}
:global .zola-ui-icon-headset::before {
  content: '\e815';
}
:global .zola-ui-icon-calendar::before {
  content: '\e816';
}
:global .zola-ui-icon-invitefriend::before {
  content: '\e817';
}
:global .zola-ui-icon-folder::before {
  content: '\e818';
}
:global .zola-ui-icon-cake::before {
  content: '\e819';
}
:global .zola-ui-icon-visible::before {
  content: '\e81a';
}
:global .zola-ui-icon-gear-1::before {
  content: '\e81b';
}
:global .zola-ui-icon-store::before {
  content: '\e81c';
}
:global .zola-ui-icon-star::before {
  content: '\e81d';
}
:global .zola-ui-icon-smartphone::before {
  content: '\e81e';
}
:global .zola-ui-icon-price-tag::before {
  content: '\e81f';
}
:global .zola-ui-icon-play::before {
  content: '\e820';
}
:global .zola-ui-icon-piggybank::before {
  content: '\e821';
}
:global .zola-ui-icon-package::before {
  content: '\e822';
}
:global .zola-ui-icon-pencil::before {
  content: '\e823';
}
:global .zola-ui-icon-notes::before {
  content: '\e824';
}
:global .zola-ui-icon-newspaper::before {
  content: '\e825';
}
:global .zola-ui-icon-magnifying-glass::before {
  content: '\e826';
}
:global .zola-ui-icon-info::before {
  content: '\e827';
}
:global .zola-ui-icon-heart::before {
  content: '\e828';
}
:global .zola-ui-icon-lock::before {
  content: '\e829';
}
:global .zola-ui-icon-link::before {
  content: '\e82a';
}
:global .zola-ui-icon-globe::before {
  content: '\e82b';
}
:global .zola-ui-icon-group::before {
  content: '\e82c';
}
:global .zola-ui-icon-flight::before {
  content: '\e82d';
}
:global .zola-ui-icon-terminal::before {
  content: '\e82e';
}
:global .zola-ui-icon-trash::before {
  content: '\e82f';
}
:global .zola-ui-icon-truck::before {
  content: '\e830';
}
:global .zola-ui-icon-checkmark::before {
  content: '\e831';
}
:global .zola-ui-icon-cart::before {
  content: '\e832';
}
:global .zola-ui-icon-pricematch-1::before {
  content: '\e833';
}
:global .zola-ui-icon-play-circled::before {
  content: '\e834';
}
:global .zola-ui-icon-hotel::before {
  content: '\e835';
}
:global .zola-ui-icon-chevron-down::before {
  content: '\e836';
}
:global .zola-ui-icon-chevron-left::before {
  content: '\e837';
}
:global .zola-ui-icon-chevron-right::before {
  content: '\e838';
}
:global .zola-ui-icon-clock-1::before {
  content: '\e839';
}
:global .zola-ui-icon-twitter::before {
  content: '\e83a';
}
:global .zola-ui-icon-facebook::before {
  content: '\e83b';
}
:global .zola-ui-icon-gplus::before {
  content: '\e83c';
}
:global .zola-ui-icon-pinterest-circled::before {
  content: '\e83d';
}
:global .zola-ui-icon-instagram::before {
  content: '\e83e';
}
:global .zola-ui-icon-videocam::before {
  content: '\e83f';
}
:global .zola-ui-icon-close::before {
  content: '\e840';
}
:global .zola-ui-icon-play-2::before {
  content: '\e841';
}
:global .zola-ui-icon-facebook-square::before {
  content: '\e842';
}
:global .zola-ui-icon-download-alt::before {
  content: '\e843';
}
:global .zola-ui-icon-gear::before {
  content: '\e844';
}
:global .zola-ui-icon-poi::before {
  content: '\e845';
}
:global .zola-ui-icon-invite-partner::before {
  content: '\e846';
}
:global .zola-ui-icon-overflow-dots::before {
  content: '\e847';
}
:global .zola-ui-icon-link-arrow::before {
  content: '\e848';
}
:global .zola-ui-icon-rsvp::before {
  content: '\e84b';
}
:global .zola-ui-icon-wedding_party::before {
  content: '\e84d';
}
:global .zola-ui-icon-home::before {
  content: '\e84e';
}
:global .zola-ui-icon-travel::before {
  content: '\e84f';
}
:global .zola-ui-icon-note::before {
  content: '\e850';
}
:global .zola-ui-icon-faq::before {
  content: '\e853';
}
:global .zola-ui-icon-couple::before {
  content: '\e855';
}
:global .zola-ui-icon-family::before {
  content: '\e856';
}
:global .zola-ui-icon-individual::before {
  content: '\e857';
}
:global .zola-ui-icon-photo::before {
  content: '\e858';
}
:global .zola-ui-icon-registry::before {
  content: '\e859';
}
:global .zola-ui-icon-event::before {
  content: '\e85a';
}
:global .zola-ui-icon-filter::before {
  content: '\e85b';
}
:global .zola-ui-icon-print::before {
  content: '\e85c';
}
:global .zola-ui-icon-bulb::before {
  content: '\e85d';
}
:global .zola-ui-icon-chevron-left-bold::before {
  content: '\e862';
}
:global .zola-ui-icon-chevron-right-bold::before {
  content: '\e863';
}
:global .zola-ui-icon-link-ext-v2::before {
  content: '\e864';
}
:global .zola-ui-icon-reorder::before {
  content: '\e884';
}
:global .zola-ui-icon-profile-photo::before {
  content: '\e88e';
}
:global .zola-ui-icon-photo-upload::before {
  content: '\e88f';
}
:global .zola-ui-icon-plus::before {
  content: '\ea0a';
}
:global .zola-ui-icon-minus::before {
  content: '\ea0b';
}
:global .zola-ui-icon-share::before {
  content: '\ea7d';
}
:global .zola-ui-icon-move::before {
  content: '\f047';
}
:global .zola-ui-icon-link-ext::before {
  content: '\f08e';
}
:global .zola-ui-icon-plane::before {
  content: '\e865';
}
:global .zola-ui-icon-two-hearts::before {
  content: '\e866';
}
:global .zola-ui-icon-two-rings::before {
  content: '\e867';
}
:global .zola-ui-icon-group-alt::before {
  content: '\e868';
}
:global .zola-ui-icon-gift-alt::before {
  content: '\e86c';
}
:global .zola-ui-icon-dollar-sign::before {
  content: '\e849';
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
/**
* NOTE:  Uncomment this file for local zola-ui development
* This file is created by `npm run zola-ui` for local zola-ui development and
* integration with web-preauth. @font-face are stripped out to make it work.
*/
