@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.lightGrayBg {
  background-color: @color-privilege;
}

.paleYellow {
  background-color: @color-pale-yellow;
}

.infoTilesSection {
  :global {
    .info-tiles-section__tile {
      .desktop({
            flex: 0 0 25%;
            max-width: 25%;
        });
      .tablet({
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 0;
            margin-bottom: 24px;
        });
    }
  }
}
.infoTile {
  :global {
    .porthole-img__container {
      background-color: @color-white;
      border-radius: 50%;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    .zola-ui.pre-auth-porthole-image .porthole-img__container .porthole-img {
      width: 75%;
    }
    .not-mobile({
        .info-tile__cta {
            display: block !important;
            margin-top: 12px;
        }
  });
  }
}
.title {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  .not-desktop({
    max-width: 450px;
    });
}
