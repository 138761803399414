@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
.container {
  width: 100%;
  margin-left: 20px;
  .mobile({
    display: inline-flex;
    align-items: center;
  });
}

.headerText {
  font-size: @zui-fontsize-larger;
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
  margin-bottom: 12px;
  .mobile({
    font-size: @zui-fontsize-large;
  });
}

.tagRow {
  display: flex;
  a {
    display: inline-flex;
    align-items: center;
  }
}
