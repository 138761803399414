@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.section {
  background-color: @color-privilege;
  text-align: center;
  & > .sectionTitle {
    margin-bottom: 14px;
  }
  & > .dek {
    margin: 0 auto 30px;
    max-width: 706px;
    font-family: @font-family-base;
    color: @color-emperor;
    font-weight: @proxima-regular;
    .text-size--compact(@zui-fontsize-regular);
  }
}

.carousel {
  overflow: hidden;
  display: flex;
  .mobile({
    flex-wrap: wrap;
  });

  .arrow {
    position: absolute;
    top: 50%;
    top: calc(50% - 30px);
  }

  .item {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    .mobile({
      max-height: 300px;
    });
  }

  .imageContainer {
    height: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  .image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    max-width: 100%;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    background-color: #fcfcfc;
  }

  .portrait {
    margin-right: 20px;
    margin-left: 20px;
    .mobile({
      margin: 0;
    });
  }

  .landscape {
    width: 100%;
    max-width: 315px;
  }

  :global {
    // DO NOT MODIFY ANY LIBRARY CODE BELOW THIS LINE UNLESS YOU HAVE A GOOD REASON
    .react-multi-carousel-list {
      display: flex;
      align-items: center;
      overflow: unset;
      position: relative;
    }

    .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
      height: 100%;
    }

    .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 35px;
      z-index: @zIndex-content-middle;
      border: 0;
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
    }

    .react-multiple-carousel__arrow--left {
      left: -20px;
      .not-desktop({
        left: -10px;
      });
    }

    .react-multiple-carousel__arrow--right {
      right: -20px;
      .not-desktop({
        right: -10px;
      });
    }

    .react-multi-carousel-item {
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }

      .react-multi-carousel-track {
        overflow: visible !important;
      }
    }
  }
}

.tileContent {
  width: 100%;
  padding: 5%;
}
.tileImg {
  width: 100%;
  display: block;
}
.tileTextbox {
  background: @color-white;
  text-align: left;
  padding: 7% 4%;
  height: 95px;
}
.tileTitle {
  margin-bottom: 0;
  font-weight: @font-weight-semi-bold;
}

.container {
  position: relative;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  .tablet({
    padding: 0 40px;
  });
}

.arrows {
  top: 40%;
}

.rightArrow {
  right: 0;
}

.leftArrow {
  left: 0;
}

.ctaContainer {
  padding-top: 30px;
}
