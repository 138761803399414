@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.registryAdvisorContainer {
  background-color: @color-white;
  background-size: cover;
  background-position: center;
  background-image: url('@zola/zola-ui/src/assets/images/icons/registry_advisor.svg');
  background-repeat: no-repeat;

  .mobile({
      background-size: 150px;
      background-position: bottom -25px left -50px;
    });

  .desktop({
    background-size: 150px;
    background-position: bottom -25px left -50px;
    });
  .tablet({
    background-size: 225px;
    background-position: bottom -40px left -75px;
    });
}
.reviewContainer {
  background-color: @color-plum;
}

.reviewText {
  font-family: @font-family-base;
  font-weight: @font-weight-medium;
}

.whiteText {
  color: @color-white;
}

.thinText {
  font-weight: @proxima-regular;
}
