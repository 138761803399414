@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.lowestCashFundContainer {
  background-color: @color-white;
  background-size: cover;
  background-position: center;
  background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/lowestCashFund/lowest_cash_fund_desktop.svg');
  .tablet({
        background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/lowestCashFund/lowest_cash_fund_tablet.svg');
    });
  .mobile({
        background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/lowestCashFund/lowest_cash_fund_mobile.svg');
    });
}
.postWeddingCardContainer {
  background-color: @color-pink;
  background-size: cover;
  background-position: center;
  background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/postWeddingDiscount/post-wedding-discount-desktop.svg');
  .tablet({
    background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/postWeddingDiscount/post-wedding-discount-tablet.svg');
  });
  .mobile({
    background-image: url('../../../../../public/static-preauth/images/WeddingRegistryBenefitsLP/postWeddingDiscount/post-wedding-discount-mobile.svg');
  });
}

.plumText {
  color: @color-plum;
}
