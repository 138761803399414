@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.hero {
  display: flex;
  .not-desktop({
      flex-direction: column;
    });
}
.borderBottom {
  .desktop({
    border-bottom: solid 1px @color-alto;
  });
}
.heroCopyContainer {
  .desktop({
  width: 50%;
    });
  :global(p) {
    font-family: @font-family-base;
    font-size: @zui-fontsize-regular;
    line-height: 32px;
    font-weight: @proxima-regular;
  }
}
.title {
  .desktop({
      font-size: 40px;
      line-height: 52px;
    });
}
.pageExcerpt {
  margin-top: 16px;
  margin-bottom: 24px;
  .not-desktop({
      margin-top: 20px;
      margin-bottom: 24px;
    });
}
.author {
  margin-bottom: 4px;
}
.updatedAt {
  margin-bottom: 16px !important;
  font-size: 14px !important;
}
.updatedAt {
  margin-bottom: 12px !important;
  font-size: 14px !important;
}
.heroImageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .desktop({
      width: 50%;
      margin-left: 30px;
    });

  img {
    width: 100%;
  }
}
.heroCredit {
  font-weight: @proxima-semi-bold;
  font-size: 14px;
}
